import React from 'react';
import { createBrowserRouter } from 'react-router-dom';
import App from '../views/App';
import ErrorPage from '../views/ErrorPage';
import { Login } from '../views/Login';
import { Layout } from '../Components/Layout';
import Administration from '../views/Administration';
import { Attributes } from '../views/Attributes';
import AddAttribute from '../views/Attributes/AddAttribute';
import ReadAttribute from '../views/Attributes/ReadAttribute';
import EditAttribute from '../views/Attributes/EditAttribute';
import { Indicators } from '../views/Indicators';
import { AddIndicator } from '../views/Indicators/AddIndicator';
import { EditUser } from '../views/Administration/EditUser';
import EditIndicator from '../views/Indicators/EditIndicator';
import { ReadIndicator } from '../views/Indicators/ReadIndicator';
import ReadUser from '../views/Administration/ReadUser';
import AddExample from '../views/Indicators/Examples/AddExample';

/**
 * Les routes disponibles pour l'application.
 *
 * La constante permet de reprendre les routes pour un cas de test.
 */
export const routes = [
  {
    path: '/',
    element: <Layout />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <App />,
      },
      {
        path: 'connexion',
        element: <Login />,
        errorElement: <ErrorPage />,
      },
      {
        path: 'administration',
        element: <Administration />,
        errorElement: <ErrorPage />,
      },
      {
        path: 'administration/:id',
        element: <ReadUser />,
        errorElement: <ErrorPage />,
      },
      {
        path: 'administration/:id/modification',
        element: <EditUser />,
        errorElement: <ErrorPage />,
      },
      {
        path: 'attributs',
        element: <Attributes />,
        errorElement: <ErrorPage />,
      },
      {
        path: 'attributs/ajout',
        element: <AddAttribute />,
        errorElement: <ErrorPage />,
      },
      {
        path: 'attributs/:id',
        element: <ReadAttribute />,
        errorElement: <ErrorPage />,
      },
      {
        path: 'attributs/:id/modification',
        element: <EditAttribute />,
        errorElement: <ErrorPage />,
      },
      {
        path: 'indicateurs',
        element: <Indicators />,
        errorElement: <ErrorPage />,
      },
      {
        path: 'indicateurs/:id',
        element: <ReadIndicator />,
        errorElement: <ErrorPage />,
      },
      {
        path: 'indicateurs/ajout',
        element: <AddIndicator />,
        errorElement: <ErrorPage />,
      },
      {
        path: 'indicateurs/:id/modification',
        element: <EditIndicator />,
        errorElement: <ErrorPage />,
      },
      {
        path: 'indicateurs/:id/exemples/ajout',
        element: <AddExample />,
        errorElement: <ErrorPage />,
      },
    ],
  },
  {
    path: '*',
    element: <ErrorPage />,
  },
];

/**
 * Le routeur de l'application.
 */
const router = createBrowserRouter(routes);

export default router;
