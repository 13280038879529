import { useMutation } from '@apollo/client';
import React, { useState } from 'react';
import {
  ADD_EXAMPLE,
  ADD_FILE_DOCUMENT,
  ADD_LINK_DOCUMENT,
} from '../../../graphql/mutations';
import AggregateErrors from '../../../utils/AggregateErrors';
import axios from 'axios';
import { ReturnLayout } from '../../../Components/ReturnLayout';
import { useNavigate, useParams } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import ExampleForm, {
  type ExampleFormData,
} from '../../../Components/ExampleForm';

/**
 * Ce composant permet d'afficher le formulaire nécessaire pour
 * l'ajout d'un exemple associé à un indicateur.
 *
 * @returns Le composant rendu par React
 */
export default function AddExample(): React.ReactElement {
  // <!-- Hooks GraphQL -->
  const [
    addFileDocument,
    {
      data: addFileDocumentData,
      error: addFileDocumentError,
      loading: addFileDocumentLoading,
    },
  ] = useMutation(ADD_FILE_DOCUMENT);
  const [
    addLinkDocument,
    {
      data: addLinkDocumentData,
      error: addLinkDocumentError,
      loading: addLinkDocumentLoading,
    },
  ] = useMutation(ADD_LINK_DOCUMENT);
  const [
    addExample,
    {
      data: addExampleData,
      error: addExampleError,
      loading: addExampleLoading,
    },
  ] = useMutation(ADD_EXAMPLE);

  // <!-- Hooks de React -->
  const navigate = useNavigate();
  const [uploadDocumentLoading, setUploadDocumentLoading] = useState(false);
  const [uploadDocumentLoadingError, setUploadDocumentError] = useState('');
  const [isComplete, setComplete] = useState(false);

  // <!-- Constantes d'erreurs GraphQL -->
  const addFileDocumentErrors = AggregateErrors(
    addFileDocumentError,
    addFileDocumentData?.addFileDocument.errors
  );
  const addLinkDocumentErrors = AggregateErrors(
    addLinkDocumentError,
    addLinkDocumentData?.addLinkDocument.errors
  );
  const addExampleErrors = AggregateErrors(
    addExampleError,
    addExampleData?.addExample.errors
  );
  const errors = addFileDocumentErrors.concat(
    ...addLinkDocumentErrors,
    ...addExampleErrors
  );
  if (uploadDocumentLoadingError !== '') {
    errors.push(uploadDocumentLoadingError);
  }

  // <!-- Autres variables -->
  const { id } = useParams();

  // <!-- Autres fonctions -->
  const sendExample = (documentId: string, remark: string): void => {
    addExample({
      variables: {
        indicatorId: id ?? '',
        documentId,
        remark,
      },
    })
      .then(addExampleResult => {
        if (
          addExampleResult.errors == null &&
          addExampleResult.data?.addExample.errors == null
        ) {
          navigate(`/indicateurs/${id}`);
        } else {
          setComplete(true);
        }
      })
      .finally(() => {
        setComplete(true);
      });
  };

  // <!-- Fonction de rappel des événements -->
  const onFormSubmit = (
    event: React.FormEvent<HTMLFormElement>,
    data: ExampleFormData,
    setValidated: React.Dispatch<React.SetStateAction<boolean>>
  ): void => {
    if (
      !addFileDocumentLoading &&
      !addLinkDocumentLoading &&
      !addExampleLoading
    ) {
      event.preventDefault();
      event.stopPropagation();

      const authors = data.authors?.map(tmp => tmp);
      setComplete(false);
      if (
        data.title !== '' &&
        data.abstract !== '' &&
        data.remark !== '' &&
        data.type !== undefined &&
        data.authors.every(a => a !== '') &&
        id !== undefined &&
        ((data.type === 'file' &&
          data.fileName !== undefined &&
          data.fileName !== '' &&
          data.document !== undefined &&
          data.document !== null) ||
          (data.type === 'link' && data.url !== undefined && data.url !== ''))
      ) {
        if (data.type === 'link') {
          addLinkDocument({
            variables: {
              title: data.title,
              abstract: data.abstract,
              url: data.url ?? '',
              publicationYear: data.publicationYear,
              authors,
            },
          })
            .then(result => {
              if (
                result.errors == null &&
                result.data?.addLinkDocument.errors == null
              ) {
                sendExample(
                  result.data?.addLinkDocument?.document?.id ?? '',
                  data.remark ?? ''
                );
              } else {
                setComplete(true);
              }
            })
            .catch(() => {
              setComplete(true);
            });
        } else {
          addFileDocument({
            variables: {
              title: data.title,
              abstract: data.abstract,
              publicationYear: data.publicationYear,
              authors,
              fileName: data.fileName ?? '',
            },
          })
            .then(result => {
              if (
                result.errors == null &&
                result.data?.addFileDocument.errors == null
              ) {
                setUploadDocumentLoading(true);
                axios
                  .post(
                    result.data?.addFileDocument.uploadToken?.uploadURL ?? '',
                    data.document,
                    {
                      headers: {
                        'Content-Type': 'application/pdf',
                      },
                      withCredentials: true,
                    }
                  )
                  .then(response => {
                    if (response.data.id !== null) {
                      sendExample(response.data.id, data.remark ?? '');
                    }
                  })
                  .catch(error => {
                    if (error.response.status === 409) {
                      sendExample(
                        error.response.data.ressourceId,
                        data.remark ?? ''
                      );
                    } else if (error.response !== undefined) {
                      setUploadDocumentError(error.response.data);
                      setComplete(true);
                    } else if (error.request !== undefined) {
                      setUploadDocumentError(error.request);
                      setComplete(true);
                    } else {
                      setUploadDocumentError(error.message);
                      setComplete(true);
                    }
                  })
                  .finally(() => {
                    setUploadDocumentLoading(false);
                  });
              } else {
                setComplete(true);
              }
            })
            .catch(() => {
              setComplete(true);
            });
        }
      } else {
        setComplete(true);
      }
      setValidated(true);
    }
  };

  return (
    <>
      <ReturnLayout href={`/indicateurs/${id}`}>
        <Row className="justify-content-md-start">
          <Col md="10">
            <div className="h2">Ajouter un exemple</div>
          </Col>
        </Row>
        <Row>
          <Col>
            <ExampleForm
              onFormSubmit={onFormSubmit}
              isComplete={isComplete}
              errors={errors}
              loading={
                addFileDocumentLoading ||
                addExampleLoading ||
                addLinkDocumentLoading ||
                uploadDocumentLoading
              }
            />
          </Col>
        </Row>
      </ReturnLayout>
    </>
  );
}
