import React from 'react';
import { Col, Nav, Row, Tab } from 'react-bootstrap';
import { UserManager } from './UserManager';
import UserRegistration from './UserRegistration';
import { useApolloClient } from '@apollo/client';

/**
 * Vue pour l'administration des utilisateurs et accès de l'application.
 *
 * La vue gère les différents menus d'administration.
 *
 * Route: /administration.
 *
 * @returns La vue, rendue par React.
 */
export default function Administration(): React.ReactElement {

  const client = useApolloClient();

  const refetchUsers = (): void => {
    client.refetchQueries({
      include: ["ListUsers"],
    })
  };

  return (
    <Tab.Container defaultActiveKey="ManageUsers">
      <Row>
        <Col md="auto" xs="auto">
          <div className='py-3 pe-3 border-end border-3'> 
            <Nav
              variant="pills"
              className="flex-column">
              <Nav.Item>
                <Nav.Link data-testid="userTab" eventKey="ManageUsers">Gestion des utilisateur(rice)s</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link data-testid="addUserTab" eventKey="RegisterUser">Enregistrement d&apos;un utilisateur(rice)</Nav.Link>
              </Nav.Item>
            </Nav>
          </div>
        </Col>
        <Col>
          <Tab.Content>
            <Tab.Pane eventKey="ManageUsers" onEnter={refetchUsers}>
              <UserManager />
            </Tab.Pane>
            <Tab.Pane eventKey="RegisterUser">
              <UserRegistration />
            </Tab.Pane>
          </Tab.Content>
        </Col>
      </Row>
    </Tab.Container>
  );
}

