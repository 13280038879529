import React from 'react';
import { ReturnLayout } from '../../Components/ReturnLayout';
import { Col, Row } from 'react-bootstrap';
import { IndicatorForm } from '../../Components/IndicatorForm';
import { useMutation } from '@apollo/client';
import { ADD_INDICATOR } from '../../graphql/mutations';
import type { IndicatorAttributeInput } from '../../__generated__/graphql';
import AggregateErrors from '../../utils/AggregateErrors';
import { useNavigate } from 'react-router-dom';

/*
* Ce composant permet l'ajout d'un indicateur à l'application. Ce composant
* est disponible à partir de l'URL `/indicateurs/ajout`
* @returns Le composant rendu par React
 */
export function AddIndicator(): React.ReactElement {

  // La mutation nécessaire à l'ajout de l'indicateur
  const [addIndicator, { data, error, loading }] = useMutation(ADD_INDICATOR, {
    onError: (e) => { console.error(e)},
  });

  const errors = AggregateErrors(error, data?.addIndicator.errors);
  const isComplete = data !== undefined || error !== undefined;
  const navigate = useNavigate();

  // La fonction de rappel lors de la soumission du formulaire
  const onFormSubmit = (ias: IndicatorAttributeInput[]): void => {
    console.log("Trying to send the request");
    if (!loading && ias.every(ia => ia.value != null && ia.value !== '')) {
      addIndicator({
        variables: {
          attributes: ias
        }
      }).then(result => {
        if (
          result.errors == null &&
          result.data?.addIndicator.errors == null
        ) {
          navigate('/indicateurs');
        }
      });
    }
  };

  return (
    <>
      <ReturnLayout href="/indicateurs">
        <Row className="justify-content-md-start">
          <Col md="10">
            <div className="h2">Ajouter un indicateur</div>
          </Col>
        </Row>
        <Row>
          <IndicatorForm
            onFormSubmit={onFormSubmit}
            isComplete={isComplete}
            loading={loading}
            errors={errors}
          />
        </Row>
      </ReturnLayout>
    </>
  );
}
