import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useMutation } from '@apollo/client';
import { ADD_ATTRIBUTE } from '../../graphql/mutations';
import { useNavigate } from 'react-router-dom';
import AggregateErrors from '../../utils/AggregateErrors';
import AttributeForm, { type FormData } from '../../Components/AttributeForm';
import { ReturnLayout } from '../../Components/ReturnLayout';

/**
 * Ce composant permet l'ajout d'un attribut à l'application. Ce composant
 * est disponible à partir de l'URL `/attributs/ajout`
 * @returns Le composant rendu par React
 */
export default function AddAttribute(): React.ReactElement {
  const [addAttribute, { loading, data, error }] = useMutation(ADD_ATTRIBUTE, {
    onError: () => {},
  });
  const errors = AggregateErrors(error, data?.addAttribute.errors);
  const isComplete = data !== undefined || error !== undefined;

  const navigate = useNavigate();

  const onFormSubmit = (
    event: React.FormEvent<HTMLFormElement>,
    data: FormData,
    setValidated: React.Dispatch<React.SetStateAction<boolean>>
  ): void => {
    if (!loading) {
      event.preventDefault();
      event.stopPropagation();
      const values = data.values?.map(tmp => tmp.value);
      if (
        data.name != null &&
        data.name !== '' &&
        data.description != null &&
        data.description !== '' &&
        data.valueType != null &&
        data.mandatory != null &&
        data.unique != null
      ) {
        addAttribute({
          variables: {
            name: data.name,
            description: data.description,
            valueType: data.valueType,
            mandatory: data.mandatory,
            unique: data.unique,
            values,
          },
        }).then(result => {
          if (
            result.errors == null &&
            result.data?.addAttribute.errors == null
          ) {
            navigate('/attributs');
          }
        });
      }
      setValidated(true);
    }
  };

  return (
    <ReturnLayout href="/attributs">
      <Row>
        <Col>
          <div className="h2">Ajouter un attribut</div>
        </Col>
      </Row>
      <Row>
        <Col>
          <AttributeForm
            onFormSubmit={onFormSubmit}
            isComplete={isComplete}
            errors={errors}
            loading={loading}
          />
        </Col>
      </Row>
    </ReturnLayout>
  );
}
