import type { ApolloError, OperationVariables, TypedDocumentNode } from "@apollo/client"
import { useQuery } from "@apollo/client";
import React from "react";
import { Alert, Spinner } from "react-bootstrap";

/**
 * Les propriétés du composant `<ContentLoader>`.
 * Certaines propriétés sont optionnelles et détiennent
 * des valeurs par défaut.
 */
export interface ContentLoaderProps<T,U> 
{
  /**
   * La fonction loading permet de définir le rendu du composant
   * lors du chargement du contenu. Cette fonction est optionnelle.
   * 
   * Valeur par défaut : Affiche une icône de chargement tournant en rond
   */
  loading? : () => React.ReactElement;

  /**
   * La fonction 'error' permet de définir le rendu du composant
   * lorsque la requête se termine avec une erreur. Cette fonction
   * est optionelle
   * @param error L'erreur reçu en réponse à la requête
   * 
   * Valeur par défaut : un composant d'alerte incluant le message d'erreur
   */
  error? : (error: ApolloError) => React.ReactElement;

  /**
   * La fonction data permet de définir le rendu du composant 
   * une fois les données reçues. Cette fonction est obligatoire.
   * @param data Les données reçues suite à la requête
   */
  data : (data: T | undefined) => React.ReactElement;

  /**
   * La requête pour laquelle le contenu doit être rendu
   * 
   * Ne pas oublier d'exécuter la commande 'npm run compile'
   * après avoir écrit la requête pour générer les types requis.
   */
  query: TypedDocumentNode<T,U>

  /**
   * Les variables nécessaires au chargement du contenu. Les requêtes
   * ne nécessitant pas de variables doivent laisser cette propriété
   * nulle.
   */
  variables?: OperationVariables 
}

/**
 * Cet objet contient le comportement par défaut des fonctions optionnelles
 */
const defaultContentLoaderProps =
{
  loading(): React.ReactElement
  {
    return (
      <div>
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>    
    );
  },
  error(error: ApolloError): React.ReactElement
  {
    return (
      <div>
        <Alert variant="danger">
          { error.name + " : " + error.message }
        </Alert>
      </div>
    )
  }
}

/**
 * Le ContentLoader permet de charger le contenu d'une requête dans 
 * le format spécifié
 * @param propsIn Les différentes fonctions qui permettent de faire le rendu incluant
 * obligatoirement le rendu des données et la requête a exécuter. On peut aussi spécifier
 * le rendu du chargement et des erreurs
 * @returns Le composant rendu par React
 */
export default function ContentLoader<T,U>(propsIn : ContentLoaderProps<T,U>): React.ReactElement
{
  const props = { ...defaultContentLoaderProps, ...propsIn }
  
  const { loading, error, data } = useQuery(
    props.query,
    {
      variables: props.variables,
      notifyOnNetworkStatusChange: true,
      onError: (e) => { console.error(e) }
    });

  if (loading) return props.loading();
  if (error !== undefined) return props.error(error);
  
  return props.data(data);
}