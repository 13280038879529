import type { ApolloError } from '@apollo/client';

/**
 * Cette interface décrit la structure d'une erreur
 */
export interface DataError {
  message: string
}

/**
 * Cette fonction permet de mettre dans un format commun les différents
 * types d'erreur qui peuvent survenir lors d'une requête GraphQL.
 * @param apolloError Les erreurs de type Network et GraphQL
 * @param dataError Les erreurs présentent au sein du résultat d'une requête
 * @param errors Un tableau d'erreur contenant des erreurs.
 * @returns Un tableau contenant toutes les erreurs
 */
export default function AggregateErrors(
  apolloError: ApolloError | undefined,
  dataError: DataError[] | null | undefined,
  errors: string[] = []
): string[] {

  if (apolloError != null) {
    errors.push(`${apolloError.name} : ${apolloError.message}`);
    apolloError.clientErrors.map(e => errors.push(`${e.name} : ${e.message}`));
    apolloError.graphQLErrors.map(e => errors.push(`${e.name} : ${e.message}`));
    apolloError.protocolErrors.map(e => errors.push(`${e.message}`));
    if (apolloError.networkError != null)
      errors.push(
        `${apolloError.networkError.name} : ${apolloError.networkError.message}`
      );
  }

  if (dataError != null) {
    dataError.map((e: DataError) => errors.push(e.message));
  }

  return errors;
}
