import React from 'react';
import { FloatingLabel, Form } from 'react-bootstrap';

import './FloatingFileInput.scss';

/**
 * Les paramètres nécessaires à l'affichage du composant
 */
export interface FloatingFileInputProps {
  /**
   * L'identifiant unique du composant
   */
  id: string;

  /**
   * L'identifiant utilisé pour tester le composant
   */
  testId?: string;

  /**
   * Le nom donné à la boîte de texte. Ce paramètre est affiché à l'utilisateur
   */
  label: string;

  /**
   * L'action à prendre lorsque l'utilisateur interagit avec la zone de texte
   * @param input L'état de la boîte de texte
   * @returns Ne retourne rien
   */
  onChange: (input: React.ChangeEvent<HTMLInputElement>) => void;

  /**
   * Drapeau indiquant l'obligation d'utilisation du champ
   */
  required: boolean;

  /**
   * Le texte affiché à l'écran si l'état du composant est invalide
   *
   * Ce paramètre est optionnel
   */
  invalidFeedback?: string;

  /**
   * Un texte d'aide pouvant être affiché à l'utilisateur sous le composant
   *
   * Ce paramètre est optionnel
   */
  helpBlock?: string;

  /**
   * Les propriétés CSS à appliquer au FloatingLabel
   */
  className?: string;

  /**
   * Les extensions de fichier à accepter.
   *
   * Ce paramètre est obligatoire. La valeur par défaut consiste à accepter tous les fichiers.
   */
  accept?: string;
}

/**
 * Ce composant permet l'affichage d'une boîte de sélection de fichier de type `FloatingLabel`
 * standardisé pour l'utilisation au sein de notre application.
 *
 * Ce composant ressemble fortement à `FloatingTextInput` à l'exception qui rend la valeur facultative.
 * Cette valeur facultative permet d'éviter des erreurs d'états dans React.
 * Pour plus d'information, cette [page Stack Overflow indique le problème](https://stackoverflow.com/questions/70730587/getting-invalidstateerror-an-attempt-was-made-to-use-an-object-that-is-not-or).
 *
 * @param props Les différents paramètres nécessaire à l'affichage du composant
 * @returns Le composant rendu par React
 */
export default function FloatingFileInput(
  props: FloatingFileInputProps
): React.ReactElement {
  return (
    <FloatingLabel
      controlId={props.id}
      label={props.label}
      className={props.className}
    >
      <Form.Control
        data-testid={props.testId}
        type="file"
        aria-label={`${props.id}-${props.label}`}
        onChange={props.onChange}
        required={props.required}
        accept={props.accept}
      />
      {props.invalidFeedback != null && (
        <Form.Control.Feedback type="invalid">
          {props.invalidFeedback}
        </Form.Control.Feedback>
      )}
      {props.helpBlock != null && (
        <Form.Text id={`${props.id}-helpblock`} muted>
          {props.helpBlock}
        </Form.Text>
      )}
    </FloatingLabel>
  );
}
