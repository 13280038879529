import React from 'react';
import './App.css';
import { Button, Col, Container, Row } from 'react-bootstrap';
import ContentLoader from '../../Components/ContentLoader';
import { ME } from '../../graphql/queries';
import type { MeQuery } from '../../__generated__/graphql';

/**
 * Composant représentant la vue racine de l'application
 */
export function App(): React.ReactElement {
  
  const displayLoginButton = (): React.ReactElement => (
    <div className="d-grid">
      <Button variant="primary" size="lg" href="/connexion">
        Se connecter
      </Button>
    </div>
  );

  const displayNothing = (data: MeQuery | undefined): React.ReactElement => (
    <></>
  );

  return (
    <Container>
      <Row>
        <Col>
          <div data-testid="app" className="bg-light p-5 border rounded-3">
            <h1>
              Base de connaissances sur les indicateurs en économie circulaire
              (BCIÉC)
            </h1>
            <p>
              Bienvenue sur la base de connaissances qui répertorie les
              indicateurs utilisés en économie circulaire.
            </p>
            <hr className="my-4" />
            <p>
              Cette base de connaissances est réalisée par Mathias St-Jean et
              Jessy Anglehart-Nunes avec le soutien de Mathias Glaus et
              d&apos;Arthur Loubert
            </p>
          </div>
        </Col>
      </Row>
      <Row className="d-flex justify-content-end mt-3">
        <Col md="4">
          <ContentLoader
            query={ME}
            data={displayNothing}
            error={displayLoginButton}
          />
        </Col>
      </Row>
    </Container>
  );
}

export default App;
