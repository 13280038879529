import React from 'react';
import { READ_ATTRIBUTE } from '../../graphql/queries';
import ContentLoader from '../ContentLoader';
import {
  AttributeValueOption,
  type ReadAttributeQuery,
} from '../../__generated__/graphql';
import { Alert, Badge, Button, Col, Row } from 'react-bootstrap';
import FloatingTextInput from '../FloatingTextInput';
import type { IndicatorFormAttribute } from '../../graphql/types';
import { X } from 'react-bootstrap-icons';
import FloatingSelectInput from '../FloatingSelectInput';

/**
 * Les propriétés nécessaires à l'affichage et au fonctionnement du composant.
 */
export interface IndicatorAttributeProps {
  /**
   * Fonction de rappel si le composant demande à être supprimé.
   * @param id L'identifiant unique du composant. Représente normalement
   * l'identifiant unique de la définition d'attribut.
   */
  onDelete: (id: string) => void;

  /**
   * Fonction de rappel permettant de mettre à jour la valeur de l'attribut
   * pour l'indicateur.
   */
  onValueChange: (id: string, value: string) => void;

  /**
   * La valeur de l'attribut pour l'indicateur.
   */
  value: string;

  /**
   * Identifiant unique de l'attribut pour lequel on veut configurer une valeur.
   */
  id: string;
}

/**
 * Ce composant permet de modifier la valeur de l'attribut pour un indicateur.
 * @param props Les propriétés nécessaires à l'affichage du composant
 * @returns Le composant rendu par React
 */
export function IndicatorAttribute(
  props: IndicatorAttributeProps
): React.ReactElement {
  // Fonction de rappel lors du changement de la valeur
  const onValueChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    props.onValueChange(props.id, event.target.value);
  };

  // Fonction permettant l'affichage des composants
  const displayContent = (
    data: ReadAttributeQuery | undefined
  ): React.ReactElement => {
    const attribute = data?.attributes?.items?.at(0);
    if (attribute == null) {
      // On retourne une erreur
      return (
        <Alert variant="danger">
          Aucun attribut possédant l&apos;identifiant unique {props.id} n&apos;a
          été trouvé.
        </Alert>
      );
    }

    return (
      <Row>
        <Col md className="mb-3">
          {renderAttributeFormElement(attribute)}
        </Col>
        <Col md={'auto'} className="mb-3 d-flex align-items-center">
          {renderAttributeDelete(attribute)}
        </Col>
      </Row>
    );
  };

  const renderAttributeDelete = (
    attribute: IndicatorFormAttribute
  ): React.ReactElement => {
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (!attribute.mandatory)
      return (
        <Button
          onClick={() => {
            props.onDelete(attribute.id);
          }}
          variant="danger"
          className="p-1"
          data-testid={`delete-${attribute.id}`}
        >
          <X size="35" />
        </Button>
      );
    return <Badge bg="secondary">Obligatoire</Badge>;
  };

  const renderAttributeFormElement = (
    attribute: IndicatorFormAttribute
  ): React.ReactElement => {
    switch (attribute.valueType) {
      case AttributeValueOption.Characters:
        return (
          <FloatingTextInput
            id={attribute.id}
            label={attribute.name}
            onChange={onValueChange}
            required={true}
            value={props.value}
            helpBlock={attribute.description}
            invalidFeedback='Veuillez renseigner cet attribut'
          />
        );
      case AttributeValueOption.Number:
        return (
          <FloatingTextInput
            id={attribute.id}
            label={attribute.name}
            type="number"
            onChange={onValueChange}
            required={true}
            value={props.value}
            helpBlock={attribute.description}
            invalidFeedback='Veuillez renseigner cet attribut'
          />
        );
      case AttributeValueOption.List:
        return (
          <FloatingSelectInput
            id={attribute.id}
            values={attribute.values?.map<string>(e => e.id)}
            elements={attribute.values?.map<string>(e => e.value) ?? []}
            defaultElement={props.value}
            onElementChange={e => {
              props.onValueChange(attribute.id, e);
            }}
            helpBlock={attribute.description}
            label={attribute.name}
            required={true}
            invalidFeedback='Veuillez renseigner cet attribut'
          />
        );
      default:
        return (
          <Alert variant="danger">
            Le type d&apos;attribut reçu est inconnu et impossible à modifier.
          </Alert>
        );
    }
  };

  return (
    <>
      <ContentLoader
        query={READ_ATTRIBUTE}
        variables={{ attributeId: props.id }}
        data={displayContent}
      />
    </>
  );
}
