import React from 'react';
import { FloatingLabel, Form } from 'react-bootstrap';

/**
 * Les paramètres nécessaires à l'affichage du composant
 */
export interface FloatingTextInputProps {
  /**
   * L'identifiant unique du composant
   */
  id: string;

  /**
   * L'identifiant utilisé pour tester le composant
   */
  testId?: string;

  /**
   * Le nom donné à la boîte de texte. Ce paramètre est affiché à l'utilisateur
   */
  label: string;

  /**
   * La valeur affiché dans la boîte de texte
   */
  value: string;

  /**
   * L'action à prendre lorsque l'utilisateur interagit avec la zone de texte
   * @param input L'état de la boîte de texte
   * @returns Ne retourne rien
   */
  onChange: (input: React.ChangeEvent<HTMLInputElement>) => void;

  /**
   * L'action à prendre lorsque l'utilisateur appuie sur une touche du clavier.
   *
   * L'événement est optionnel.
   *
   * @param event L'événement React.
   */
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;

  /**
   * Drapeau indiquant l'obligation d'utilisation du champ
   */
  required: boolean;

  /**
   * Le texte affiché à l'écran si l'état du composant est invalide
   *
   * Ce paramètre est optionnel
   */
  invalidFeedback?: string;

  /**
   * Un texte d'aide pouvant être affiché à l'utilisateur sous le composant
   *
   * Ce paramètre est optionnel
   */
  helpBlock?: string;

  /**
   * Le type de champ rendu disponible à l'utilisateur.
   *
   * Ce paramètre est optionnel.
   * Valeur par défaut : 'text'
   */
  type?: string;

  /**
   * Les propriétés CSS à appliquer au FloatingLabel
   */
  className?: string;
}

/**
 * Ce composant permet l'affichage d'une boîte de texte de type `FloatingLabel`
 * standardisé pour l'utilisation au sein de notre application
 * @param props Les différents paramètres nécessaire à l'affichage du composant
 * @returns Le composant rendu par React
 */
export default function FloatingTextInput(
  props: FloatingTextInputProps
): React.ReactElement {
  return (
    <FloatingLabel
      controlId={props.id}
      label={props.label}
      className={props.className}
    >
      <Form.Control
        data-testid={props.testId}
        type={props.type ?? 'text'}
        aria-label={`${props.id}-${props.label}`}
        value={props.value}
        onChange={props.onChange}
        onKeyDown={props.onKeyDown}
        required={props.required}
      />
      {props.invalidFeedback != null && (
        <Form.Control.Feedback type="invalid">
          {props.invalidFeedback}
        </Form.Control.Feedback>
      )}
      {props.helpBlock != null && (
        <Form.Text id={`${props.id}-helpblock`} muted>
          {props.helpBlock}
        </Form.Text>
      )}
    </FloatingLabel>
  );
}
