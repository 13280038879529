import React, { useEffect, useState } from 'react';
import { Form, Alert, Button, Col, Row } from 'react-bootstrap';
import FloatingTextInput from '../FloatingTextInput';
import FloatingSelectInput from '../FloatingSelectInput';
import SimpleActionTable from '../SimpleActionTable';
import SimpleTooltip from '../SimpleTooltip';
import { Plus, Trash3Fill } from 'react-bootstrap-icons';
import SavingStatus from '../SavingStatus';
import { ButtonLoading } from '../ButtonLoading';
import FloatingFileInput from '../FloatingFileInput';

/**
 * Les propriétés nécessaires à l'affichage du formulaire d'exemple
 */
export interface ExampleFormProps {
  /**
   * Les données utilisées pour initialiser le formulaire.
   *
   * Si ces données sont absentes, les données sont initialisée avec
   * des valeurs par défaut.
   */
  formData?: ExampleFormData;

  /**
   * L'action à prendre lorsque le formulaire est soumis.
   *
   * @param event L'événement de soumission du formulaire.
   * @param data Les données générées par le formulaire
   * @param setValidated Confirmation de validation du formulaire.
   */
  onFormSubmit: (
    event: React.FormEvent<HTMLFormElement>,
    data: ExampleFormData,
    setValidated: React.Dispatch<React.SetStateAction<boolean>>
  ) => void;

  /**
   * Indique si l'opération de soumissions est complétée.
   * Cela permet d'afficher les erreurs et la confirmation
   * d'envoi des données.
   */
  isComplete: boolean;

  /**
   * Les erreurs générées par l'envoi du formulaire.
   */
  errors: string[];

  /**
   * Si le formulaire est en cours de traitement.
   */
  loading: boolean;
}

/**
 * Les types de documents acceptés dans la plateforme.
 */
export type DocumentType = 'file' | 'link';

/**
 * Les données utilisées par le formulaire
 */
export interface ExampleFormData {
  /**
   * Le nom du fichier.
   *
   * Ce nom doit être défini si le type de document
   * à ajouter est un fichier.
   */
  fileName?: string;

  /**
   * L'URL du document.
   *
   * L'URL doit être définie si le type de document à ajouter
   * est un lien.
   */
  url?: string;

  /**
   * Le titre du document de provenance de l'exemple.
   */
  title: string;

  /**
   * Le résumé du document de provenance de l'exemple.
   */
  abstract: string;

  /**
   * La liste des auteur(e)s qui ont rédigé le document de provenance
   * de l'exemple.
   */
  authors: string[];

  /**
   * L'année de publication du document de provenance de l'exemple.
   */
  publicationYear?: number;

  /**
   * Le type d'exemple ajouté.
   *
   * Cette propriétée devrait être synchronisée avec les types acceptées
   * dans l'API.
   */
  type?: DocumentType;

  /**
   * Les commentaires associés à l'exemple.
   */
  remark?: string;

  /**
   * Les données du document à téléverser pour l'exemple.
   */
  document?: File | null;
}

/**
 * Ce composant permet d'obtenir ou de spécifier des informations en lien avec un
 * exemple au moyen d'un formulaire.
 *
 * @param props Les propriétés nécessaires pour la manipulation du formulaire.
 *
 * @return Le composant rendu par React
 */
export default function ExampleForm(
  props: ExampleFormProps
): React.ReactElement {
  // <!-- Variables d'états -->
  const [fileName, setFileName] = useState(props.formData?.fileName ?? '');
  const [url, setUrl] = useState(props.formData?.url ?? '');
  const [title, setTitle] = useState(props.formData?.title ?? '');
  const [abstract, setAbstract] = useState(props.formData?.abstract ?? '');
  const [authors, setAuthors] = useState(props.formData?.authors ?? []);
  const [publicationYear, setPublicationYear] = useState(
    props.formData?.publicationYear ?? new Date().getFullYear()
  );
  const [type, setType] = useState<DocumentType | undefined>(
    props.formData?.type
  );
  const [remark, setRemark] = useState(props.formData?.remark ?? '');
  const [document, setDocument] = useState(props.formData?.document);

  const [validated, setValidated] = useState(false);
  const [updateKey, setUpdateKey] = useState(0);

  // <!-- Effets -->
  useEffect(() => {
    setFileName(props.formData?.fileName ?? '');
    setUrl(props.formData?.url ?? '');
    setTitle(props.formData?.title ?? '');
    setAbstract(props.formData?.abstract ?? '');
    setAuthors(props.formData?.authors ?? []);
    setPublicationYear(
      props.formData?.publicationYear ?? new Date().getFullYear()
    );
    setType(props.formData?.type);
    setRemark(props.formData?.remark ?? '');
    setDocument(props.formData?.document);
  }, [props.formData]);

  // <!-- Fonction de rappel des événements -->
  /**
   * Fonction de rappel lorsque le champ de nom de fichier est modifié.
   *
   * @param event L'événement de changement de React.
   */
  const onFileNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setFileName(event.target.value);
  };

  /**
   * Fonction de rappel lorsque le champ de nom d'URL est modifié.
   *
   * @param event L'événement de changement de React.
   */
  const onUrlChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setUrl(event.target.value);
  };

  /**
   * Fonction de rappel lorsque le champ de titre est modifié.
   *
   * @param event L'événement de changement de React.
   */
  const onTitleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setTitle(event.target.value);
  };

  /**
   * Fonction de rappel lorsque le champ de résumé est modifié.
   *
   * @param event L'événement de changement de React.
   */
  const onAbstractChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setAbstract(event.target.value);
  };

  /**
   * Fonction de rappel lorsqu'une personne ajoute un auteur.
   *
   * @param event L'événement de clic de React.
   */
  const onAuthorAdd = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAuthors([...authors, '']);
  };

  /**
   * Fonction de rappel lorsqu'une personne retire un auteur.
   *
   * @param index L'indice, dans le tableau, de l'auteur à retirer.
   * @param event L'événement de clic de React.
   */
  const onAuthorRemove = (
    index: number,
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    setAuthors([...authors.slice(0, index), ...authors.slice(index + 1)]);
  };

  /**
   * Fonction de rappel lorsqu'une personne change le nom d'un auteur.
   *
   * @param index L'indice, dans le tableau, de l'autheur à modifier.
   * @param event L'événement de changement de React.
   */
  const OnAuthorChange = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setAuthors([
      ...authors.slice(0, index),
      event.target.value,
      ...authors.slice(index + 1),
    ]);
  };

  /**
   * Fonction de rappel lorsque le champ d'année de publication est modifié.
   *
   * @param event L'événement de changement de React.
   */
  const onPublicationYearChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setPublicationYear(parseInt(event.target.value));
  };

  /**
   * Fonction de rappel lorsque le champ de type est modifié.
   *
   * @param element Le type sélectionné
   */
  const onTypeChange = (element: string): void => {
    setType(element as DocumentType);
  };

  /**
   * Fonction de rappel lorsque le champ de remarques est modifié.
   *
   * @param event L'événement de changement de React.
   */
  const onRemarkChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setRemark(event.target.value);
  };

  /**
   * Fonction de rappel lorsque le champ de fichier est modifié.
   *
   * @param event L'événement de changement de React
   */
  const onDocumentChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setDocument(event.target.files?.item(0));
  };

  /**
   * Fonction de rappel lorsque le formulaire est soumis.
   *
   * @param event L'événémenet de formulaire de React.
   */
  const onFormSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    setUpdateKey(updateKey + 1);
    props.onFormSubmit(
      event,
      {
        fileName,
        url,
        title,
        abstract,
        authors,
        publicationYear,
        type,
        remark,
        document,
      },
      setValidated
    );
  };

  // <!-- Fonctions de rendu -->
  const authorDisposition: Array<
    (item: string, index: number) => React.ReactElement
  > = [
    (item, index) => (
      <FloatingTextInput
        id={`author-${index}`}
        testId={`author-${index}`}
        label="Nom de l'auteur(rice)"
        required
        value={item}
        onChange={event => {
          OnAuthorChange(index, event);
        }}
        invalidFeedback="Le nom de l'auteur(e) est requis"
        className="mb-3"
      />
    ),
    (item, index) => (
      <SimpleTooltip text="Supprimer l'auteur(rice)">
        <Button
          variant="danger"
          data-testid={`remove-author-${index}`}
          size="sm"
          onClick={event => {
            onAuthorRemove(index, event);
          }}
        >
          <Trash3Fill size={20} />
        </Button>
      </SimpleTooltip>
    ),
  ];

  return (
    <Form onSubmit={onFormSubmit} validated={validated} noValidate>
      <FloatingTextInput
        id="example-title"
        testId="example-title"
        label="Titre du document"
        required
        value={title}
        onChange={onTitleChange}
        invalidFeedback="Le titre du document associé à l'exemple est obligatoire"
        className="mb-3"
        helpBlock="Le titre du document fait référence au titre de la pièce jointe. Cela peut être le titre du rapport, de l'article scientifique, du cas d'étude, etc..."
      />
      <FloatingTextInput
        id="example-abstract"
        testId="example-abstract"
        label="Résumé du document"
        required
        value={abstract}
        onChange={onAbstractChange}
        invalidFeedback="Le résumé du document associé à l'exemple est obligatoire"
        className="mb-3"
        helpBlock="Le résumé du document fait référence au résumé de la pièce jointe. Cela peut être le résumé (abstract) de l'article scientifique ou un court résumé du document"
      />
      <FloatingTextInput
        id="example-publication-year"
        testId="example-publication-year"
        type="number"
        label="Année de publication du document"
        required={false}
        value={publicationYear.toString()}
        onChange={onPublicationYearChange}
        className="mb-3"
      />
      <FloatingTextInput
        id="example-remark"
        testId="example-remark"
        label="Remarques"
        value={remark}
        required
        onChange={onRemarkChange}
        className="mb-3"
        helpBlock="Les remarques sont des commentaires que vous souhaitez ajouter à l'exemple."
        invalidFeedback="Les remarques sont obligatoires"
      />
      <FloatingSelectInput
        id="example-type"
        testId="example-type"
        label="Type de document à joindre"
        className="mb-3"
        defaultElement={type ?? ''}
        required
        invalidFeedback="Le type de document à joindre est obligatoire"
        onElementChange={onTypeChange}
        elements={['Document à téléverser', 'Lien vers le document']}
        values={['file', 'link']}
      />
      {type !== undefined && type === 'file' && (
        <>
          <FloatingTextInput
            id="example-file-name"
            testId="example-file-name"
            label="Nom du fichier"
            value={fileName}
            required
            onChange={onFileNameChange}
            className="mb-3"
            helpBlock="Le nom du fichier que vous souhaitez donner au document. Lorsque les autres utilisateur(rice)s de la BCIÉC téléchargeront ce document, il aura le nom spécifié."
            invalidFeedback="Le nom du fichier est obligatoire"
          />
          <FloatingFileInput
            id="example-document"
            testId="example-document"
            label="Document"
            required
            onChange={onDocumentChange}
            className="mb-3"
            invalidFeedback="Le document est obligatoire"
            accept="application/pdf"
          />
        </>
      )}
      {type !== undefined && type === 'link' && (
        <FloatingTextInput
          id="example-url"
          testId="example-url"
          label="Lien vers le document"
          value={url}
          required
          onChange={onUrlChange}
          className="mb-3"
          helpBlock="Le lien vers le document"
          invalidFeedback="Le lien est obligatoire"
        />
      )}
      <Alert variant="light">
        <SimpleActionTable
          titles={['Auteur(e)s', 'Actions']}
          data={authors}
          disposition={authorDisposition}
        />
        <Button
          variant="primary"
          onClick={onAuthorAdd}
          className="mb-3"
          data-testid="author-add"
        >
          <Plus size={25} />
        </Button>
      </Alert>
      <Row>
        <Col md="8">
          <SavingStatus
            complete={props.isComplete}
            errors={props.errors}
            loading={props.loading}
            key={updateKey}
          />
        </Col>
        <Col md="4">
          <div className="d-grid gap-2">
            <ButtonLoading
              type="submit"
              variant="success"
              loading={props.loading}
            >
              Enregistrer
            </ButtonLoading>
          </div>
        </Col>
      </Row>
    </Form>
  );
}
