import { useMutation } from '@apollo/client';
import { ADD_USER } from '../../graphql/mutations';
import React, { useState } from 'react';
import { Alert, Col, Form, Row } from 'react-bootstrap';
import { ButtonLoading } from '../../Components/ButtonLoading';
import FloatingTextInput from '../../Components/FloatingTextInput';

/**
 * Ce composant permet d'ajouter un utilisateur au système
 * @returns Le composant rendu par React
 */
export default function UserRegistration(): React.ReactElement {
  const onFormSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    if (!loading) {
      event.preventDefault();
      event.stopPropagation();
      if (
        email !== '' &&
        password !== '' &&
        firstName !== '' &&
        lastName !== ''
      ) {
        addUser({
          variables: {
            firstName,
            lastName,
            email,
            password,
          },
        }).then(
          result => {
            if (result.errors == null && result.data?.addUser.errors == null) {
              setEmail('');
              setPassword('');
              setFirstName('');
              setLastName('');
              setValidated(false);
            }
          },
          () => {}
        );
      }
      setValidated(true);
    }
  };

  const onFirstNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setFirstName(event.target.value);
  };

  const onLastNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setLastName(event.target.value);
  };

  const onEmailChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setEmail(event.target.value);
  };

  const onPasswordChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setPassword(event.target.value);
  };

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [validated, setValidated] = useState(false);

  const [addUser, { loading, data, error }] = useMutation(ADD_USER);

  return (
    <div>
      <div className="h2">Ajouter un utilisateur</div>
      <Form onSubmit={onFormSubmit} validated={validated} noValidate>
        {/* Prénom */}
        <FloatingTextInput
          id="registerFirstName"
          label="Prénom"
          className="mb-3"
          onChange={onFirstNameChange}
          value={firstName}
          required={true}
          invalidFeedback="Un prénom doit être spécifié."
          type="text"
          testId='registration-firstname'
        />
        {/* Nom de famille */}
        <FloatingTextInput
          id="registerLastName"
          label="Nom de famille"
          className="mb-3"
          onChange={onLastNameChange}
          value={lastName}
          required={true}
          invalidFeedback="Un nom de famille doit être spécifié."
          type="text"
          testId='registration-lastname'
        />
        {/* Courriel */}
        <FloatingTextInput
          id="registerEmail"
          label="Courriel"
          className="mb-3"
          onChange={onEmailChange}
          value={email}
          required={true}
          invalidFeedback="Un courriel doit être spécifié."
          type="text"
          testId='registration-email'
        />
        {/* Mot de passe */}
        <FloatingTextInput
          id="registerPassword"
          label="Mot de passe"
          className="mb-3"
          onChange={onPasswordChange}
          value={password}
          required={true}
          invalidFeedback="Un mot de passe doit être spécifié."
          helpBlock="Le mot de passe doit contenir au minimum 8 caractères incluant: une majuscule, une minuscule, un chiffre et un caractère spécial."
          type="password"
          testId='registration-password'
        />
        {/* Bouton ajouter et message de status */}
        <Row>
          <Col md={8}>
            {error !== undefined && (
              <Alert key="danger" variant="danger">
                {error.name + ' : ' + error?.message}
              </Alert>
            )}
            {data?.addUser?.errors !== undefined &&
              data?.addUser?.errors !== null && (
                <Alert key="danger" variant="danger">
                  {data.addUser.errors[0].message}
                </Alert>
              )}
            {data?.addUser != null && data?.addUser?.errors == null && (
              <Alert key="success" variant="success">
                L&apos;ajout de l&apos;utilisateur s&apos;est complété avec
                succès
              </Alert>
            )}
          </Col>
          <Col md={4}>
            <div className="d-grid">
              <ButtonLoading loading={loading} variant="success" type="submit">
                Enregistrer
              </ButtonLoading>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  );
}
