import React from 'react';
import { Alert, Row } from 'react-bootstrap';
import { ConfirmModal, type ConfirmModalProps } from '../ConfirmModal';

export interface SimpleActionModelProps extends ConfirmModalProps {
  /**
   * Le message à afficher en cas de succès
   */
  successMessage?: string;

  /**
   * Les messages à afficher en cas d'échec
   */
  errorMessages?: string[];

  /**
   * Le message d'erreur générique à afficher en cas d'erreur
   */
  genericErrorMessage?: string;
}


/**
 * Ce composant enveloppe le composant `ConfirmModal` et ajoute l'affichage
 * des erreurs reliées à cette modale.
 * @param props Les propriétés nécessaires à l'affichage du composant
 * @returns Le composant rendu par React
 */
export default function SimpleActionModal(
  props: SimpleActionModelProps
): React.ReactElement {
  const renderedGenericErrorMessage =
    props.genericErrorMessage ?? 'Une erreur est survenue.';

  return (
    <>
      {(props.successMessage ?? '') !== '' && (
        <Row>
          <Alert className="mb-1" variant="success">
            {props.successMessage}
          </Alert>
        </Row>
      )}
      {(props.errorMessages?.length ?? 0) > 0 && (
        <Row>
          <Alert className="mb-3" variant="danger">
            {renderedGenericErrorMessage}
            <ul>
              {props.errorMessages?.map((e, i) => (
                <li key={i}>{e}</li>
              ))}
            </ul>
          </Alert>
        </Row>
      )}
      <ConfirmModal
        show={props.show}
        onClose={props.onClose}
        onConfirm={props.onConfirm}
        title={props.title}
        confirmButtonText={props.confirmButtonText}
      >
        {props.children}
      </ConfirmModal>
    </>
  );
}
