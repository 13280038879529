import React from 'react';
import { Button } from 'react-bootstrap';

/**
 * Les propriétés requises pour afficher le composant
 */
export interface IconTextButtonProps {
  /**
   * L'icône à afficher
   */
  children: React.ReactNode

  /**
   * Le lien utilisé pour la redirection. La redirection s'effectue après
   * l'appel à la fonction de rappel `onClick` si cette dernière est spécifiée.
   * 
   * Ce paramètre est optionnel.
   */
  href?: string

  /**
   * Le texte à afficher au côté de l'icône
   */
  text: string

  /**
   * La variante Bootstrap utilisée pour afficher le bouton
   * 
   * Valeur par défaut : `primary`
   */
  variant?: string
              
  /**
   * Fonction de rappel lorsque l'utilisateur appuie sur le bouton. Cette action
   * est effectuée avant la redirection si la propriété `href` est spécifiée.
   * 
   * Ce paramètre est optionnel.
   */
  onClick?: () => void;
}

/**
 * Ce composant permet d'afficher un bouton ayant à la fois du texte
 * et une îcone. L'îcone se trouve à la gauche du texte.
 * @param props Les propriétés nécessaire à l'affichage du composant
 * @returns Le composant rendu par React
 */
export default function IconTextButton(
  props: IconTextButtonProps
): React.ReactElement {
  return (
    <Button href={props.href} onClick={() => {props.onClick?.()}} variant={props.variant ?? 'primary'}>
      <div className="d-flex align-items-center">
        { props.children }
        <span>{ props.text }</span>
      </div>
    </Button>
  );
}
