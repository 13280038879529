import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useMutation, useApolloClient } from '@apollo/client';
import Alert from 'react-bootstrap/Alert';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

import { ButtonLoading } from '../../Components/ButtonLoading';
import { LOGIN } from '../../graphql/mutations';
import FloatingTextInput from '../../Components/FloatingTextInput';

/**
 * Vue pour la connexion vers l'application.
 *
 * La vue gère un formulaire de connexion et redirige vers les bonnes pages
 * s'il y a lieu.
 *
 * Route: /connexion.
 *
 * @returns La vue, rendue par React.
 */
export function Login(): React.ReactElement {
  const onFormSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    if (event.currentTarget.checkValidity()) {
      login({
        variables: {
          email,
          password,
        },
      }).then(result => {
        if (
          result.data?.login.errors?.length === undefined ||
          result.data?.login.errors?.length === null ||
          result.data?.login.errors?.length === 0
        ) {
          client.resetStore();
          navigate('/');
          Cookies.set('loggedIn', 'true');
        }
      });
    }
    setFormValidated(true);
  };

  const onEmailChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setEmail(event.target.value);
  };

  const onPasswordChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setPassword(event.target.value);
  };

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [formValidated, setFormValidated] = useState(false);

  const client = useApolloClient();
  const [login, { loading, data }] = useMutation(LOGIN);

  const navigate = useNavigate();

  return (
    <Form onSubmit={onFormSubmit} noValidate validated={formValidated}>
      <FloatingTextInput
        id="floatingEmail"
        label="Courriel"
        className="mb-3"
        onChange={onEmailChange}
        value={email}
        required={true}
        invalidFeedback="Un courriel doit être spécifié."
        type="email"
        testId='user-email'
      />
      <FloatingTextInput
        id="floatingPassword"
        label="Mot de passe"
        className="mb-3"
        onChange={onPasswordChange}
        value={password}
        required={true}
        invalidFeedback="Un mot de passe doit être spécifié."
        type="password"
        testId='user-password'
      />
      {data?.login.errors !== undefined && data?.login.errors !== null && (
        <Alert variant="danger">
          Des erreurs sont survenues lors de la connexion.
          <ul>
            {data.login.errors.map((e, i) => (
              <li key={i}>{e.message}</li>
            ))}
          </ul>
        </Alert>
      )}
      <ButtonLoading type="submit" loading={loading}>
        Se connecter
      </ButtonLoading>
      <Button variant="secondary" type="button" className="ms-2">
        Mot de passe oublié
      </Button>
    </Form>
  );
}
