import React from 'react';
import { useParams } from 'react-router-dom';
import { ReturnLayout } from '../../Components/ReturnLayout';
import ContentLoader from '../../Components/ContentLoader';
import { GET_USER } from '../../graphql/queries';
import type { GetUserQuery } from '../../__generated__/graphql';
import { Alert, Badge, Col, ListGroup, Row } from 'react-bootstrap';

/**
 * Ce composant permet de consulter les informations liées à un utilisateur.
 * Ce composant est accessible à partir de l'URL `/administration/:id`.
 * @returns Le composant rendu par React
 */
export default function ReadUser(): React.ReactElement {
  const { id } = useParams();

  const displayRoles = (roles: string[]): React.ReactNode => {
    if (roles.length === 0) {
      return (
        <Alert variant="warning">{`Aucun rôle n'a été assigné à cet utilisateur`}</Alert>
      );
    }
    return roles.map<React.ReactElement>(r => (
      <ListGroup.Item key={r}>{r}</ListGroup.Item>
    ));
  };

  const displayContent = (
    data: GetUserQuery | undefined
  ): React.ReactElement => {
    const user = data?.users?.items?.at(0);

    if (user == null) return <></>;

    return (
      <>
        <Row>
          <Col>
            <div className="h2">
              {`Profil de ${user?.firstName} ${user?.lastName}`}
            </div>
          </Col>
        </Row>
        <Row>
          <hr />
        </Row>
        <Row>
          <Col md="auto">
            <ListGroup>
              <ListGroup.Item>{`Prénom : ${user?.firstName}`}</ListGroup.Item>
              <ListGroup.Item>
                {`Nom de famille : ${user?.lastName}`}
              </ListGroup.Item>
              <ListGroup.Item>{`Courriel : ${user?.email}`}</ListGroup.Item>
              <ListGroup.Item>
                État du compte :{' '}
                <Badge bg={user?.state === 'Actif' ? 'success' : 'danger'}>
                  {user?.state}
                </Badge>
              </ListGroup.Item>
              <ListGroup.Item>
                {new Date(user?.lockoutEnd).toISOString() <
                new Date().toISOString()
                  ? `Ce compte est déverouillé`
                  : `Ce compte est vérouillé jusqu'au ${new Date(
                      user?.lockoutEnd
                    ).toLocaleString()}`}
              </ListGroup.Item>
            </ListGroup>
            <div className="h3 mt-3">Rôles</div>
            <ListGroup>{displayRoles(user.roles)}</ListGroup>
          </Col>
        </Row>
      </>
    );
  };

  return (
    <>
      <ReturnLayout href="/administration">
        <ContentLoader
          query={GET_USER}
          variables={{ id }}
          data={displayContent}
        />
      </ReturnLayout>
    </>
  );
}
