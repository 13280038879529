import React, { useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { ArchiveFill, Pencil, ShiftFill, FileEarmark } from 'react-bootstrap-icons';
import { useMutation } from '@apollo/client';
import Button from 'react-bootstrap/Button';
import ContentLoader from '../../Components/ContentLoader';
import { LIST_USERS } from '../../graphql/queries';
import { ButtonLoading } from '../../Components/ButtonLoading';
import { ARCHIVE_USER, RESTORE_USER } from '../../graphql/mutations';
import type { ListUsersQuery } from '../../__generated__/graphql';
import AggregateErrors from '../../utils/AggregateErrors';
import type { StandardUser } from '../../graphql/types';
import SimpleActionTable from '../../Components/SimpleActionTable';
import SimpleTooltip from '../../Components/SimpleTooltip';
import SimpleActionModal from '../../Components/SimpleActionModal';
import { Stack } from 'react-bootstrap';

/**
 * Ce composant est utilisé en tant que composant parent de ce qui est affiché
 * pour gérer les utilisateurs. Ce composant permet les fonctionnalités suivantes:
 *  - Afficher la liste des utilisateurs
 *  - Accéder à la page de modification d'un utilisateur
 *  - Archiver/restaurer un compte utilisateur
 * @returns Le composant rendu par React
 */
export function UserManager(): React.ReactElement {
  const [archiveConfirmModalShow, setArchiveConfirmModalShow] = useState(false);
  const [restoreConfirmModalShow, setRestoreConfirmModalShow] = useState(false);
  const [userIdToAction, setUserIdToAction] = useState('');
  const [successArchiveMessage, setSuccessArchiveMessage] = useState('');
  const [successRestoreMessage, setSuccessRestoreMessage] = useState('');
  const [skippedUsers, setSkippedUsers] = useState(0);

  const [
    archiveUser,
    { loading: archiveLoading, data: archiveUserData, error: archiveError },
  ] = useMutation(ARCHIVE_USER, { refetchQueries: [LIST_USERS] });
  const [
    restoreUser,
    { loading: restoreLoading, data: restoreUserData, error: restoreError },
  ] = useMutation(RESTORE_USER, { refetchQueries: [LIST_USERS] });

  /**
   * Le nombre d'élément contenu dans la page. Pourra être intégré
   * comme un choix pour l'utilisateur dans le futur.
   */
  const [pageSize, setPageSize] = useState(
    parseInt(localStorage.getItem('userPageSize') ?? '20') 
  );
  const onPageSizeChange = (size: number): void => {
    localStorage.setItem('userPageSize', size.toString());
    setPageSize(size);
  };

  const onPageSwitch = (pageNumber: number): void => {
    setSkippedUsers((pageNumber - 1) * pageSize);
  };

  /**
   * Fonction de rappel lorsqu'une personne souhaite archiver un compte.
   *
   * @param {string} userId L'utilisateur que l'on souhaite archiver.
   */
  const onArchiveAccount = (userId: string): void => {
    setUserIdToAction(userId);
    setArchiveConfirmModalShow(true);
  };

  /**
   * Fonction de rappel lorsqu'une personne souhaite restaurer un compte.
   *
   * @param {string} userId L'utilisateur que l'on souhaite restaurer.
   */
  const onRestoreAccount = (userId: string): void => {
    setUserIdToAction(userId);
    setRestoreConfirmModalShow(true);
  };

  /**
   * Fonction de rappel lorsqu'une personne annule l'archivage d'un compte.
   */
  const onArchiveClose = (): void => {
    setArchiveConfirmModalShow(false);
    setUserIdToAction('');
  };

  /**
   * Fonction de rappel lorsqu'une personne annule la restauration d'un compte.
   */
  const onRestoreClose = (): void => {
    setRestoreConfirmModalShow(false);
    setUserIdToAction('');
  };

  /**
   * Fonction de rappel lorsqu'une personne confirme l'archivage d'un compte.
   */
  const onArchiveConfirm = (): void => {
    archiveUser({
      variables: {
        userId: userIdToAction,
      },
    })
      .then(result => {
        if (
          (result.data?.archiveUser.errors ?? []).length === 0 &&
          (result.data?.archiveUser.user?.state ?? '') === 'Archivé'
        ) {
          setSuccessArchiveMessage(
            "L'utilisateur(rice) a été archivé avec succès."
          );
          setTimeout(() => {
            setSuccessArchiveMessage('');
          }, 3000);
        }
      })
      .finally(() => {
        setArchiveConfirmModalShow(false);
      });
  };

  /**
   * Fonction de rappel lorsqu'une personne confirme la restauration d'un compte.
   */
  const onRestoreConfirm = (): void => {
    restoreUser({
      variables: {
        userId: userIdToAction,
      },
    })
      .then(result => {
        if (
          (result.data?.restoreUser.errors ?? []).length === 0 &&
          (result.data?.restoreUser.user?.state ?? '') === 'Actif'
        ) {
          setSuccessRestoreMessage(
            "L'utilisateur(rice) a été restoré avec succès."
          );
          setTimeout(() => {
            setSuccessRestoreMessage('');
          }, 3000);
        }
      })
      .finally(() => {
        setRestoreConfirmModalShow(false);
      });
  };

  const archiveErrors = AggregateErrors(
    archiveError,
    archiveUserData?.archiveUser.errors
  );
  const restoreErrors = AggregateErrors(
    restoreError,
    restoreUserData?.restoreUser.errors
  );

  const titles = [
    'Prénom',
    'Nom de famille',
    'Courriel',
    'Rôles',
    'Statut',
    'Actions',
  ];

  /**
   * Ce tableau contient une fonction de rappel par colonne à présenter dans le tableau
   */
  const disposition: Array<
    (item: StandardUser, index: number) => React.ReactElement
  > = [
    (e, i) => <div>{e.firstName}</div>,
    (e, i) => <div>{e.lastName}</div>,
    (e, i) => <div>{e.email}</div>,
    (e, i) => <div>{e.roles.join(',')}</div>,
    (e, i) => <div>{e.state}</div>,
    (e, i) => (
      <>
      <Stack direction="horizontal" gap={2}>
          <SimpleTooltip text="Voir le profil de l'utilisateur">
            <Button
              href={`/administration/${e.id}`}
              variant="primary"
              data-testid={`btn-read-${e.id}`}
            >
              <FileEarmark />
            </Button>
          </SimpleTooltip>
        {e.state !== 'Archivé' && (
          <>
            <SimpleTooltip text="Modifier l'utilisateur">
              <Button
                href={`/administration/${e.id}/modification`}
                id={`btn-edit-${e.id}`}
                data-testid={`btn-edit-${e.id}`}
              >
                <Pencil />
              </Button>
            </SimpleTooltip>
            <SimpleTooltip text="Archiver l'utilisateur(rice)">
              <ButtonLoading
                variant="danger"
                onClick={() => {
                  onArchiveAccount(e.id);
                }}
                loading={e.id === userIdToAction && archiveLoading}
              >
                <ArchiveFill aria-label={`archive-${e.id}`} />
              </ButtonLoading>
            </SimpleTooltip>
          </>
        )}
        {e.state === 'Archivé' && (
          <SimpleTooltip text="Restaurer l'utilisateur">
            <ButtonLoading
              variant="warning"
              onClick={() => {
                onRestoreAccount(e.id);
              }}
              loading={e.id === userIdToAction && restoreLoading}
            >
              <ShiftFill aria-label={`restore-${e.id}`} color="white" />
            </ButtonLoading>
          </SimpleTooltip>
        )}
        </Stack>
      </>
    ),
  ];
  /**
   * Cette fonction défini comment le contenu de la requête du <ContentLoader>
   * sera affiché à l'écran
   */
  const data = (data: ListUsersQuery | undefined): React.ReactElement => {
    if (data !== undefined) {
      return (
        <SimpleActionTable
          titles={titles}
          data={data.users?.items ?? []}
          disposition={disposition}
          pageInfo={{
            skipped: skippedUsers,
            took: pageSize,
            total: data.users?.totalCount ?? 0,
          }}
          onPageSwitch={onPageSwitch}
          onPageSizeSwitch={onPageSizeChange}
        />
      );
    }
    return <></>;
  };

  return (
    <>
      <Row className="justify-content-md-center">
        <Col md="10">
          <div className="h2">Liste des utilisateur(rice)s</div>
          <SimpleActionModal
            show={archiveConfirmModalShow}
            onClose={onArchiveClose}
            onConfirm={onArchiveConfirm}
            title="Confirmer l'archivage"
            confirmButtonText="Archiver l'utilisateur(rice)"
            successMessage={successArchiveMessage}
            genericErrorMessage="Des erreurs sont survenues lors de l'archivage d'un compte."
            errorMessages={archiveErrors}
          >
            <>
              <p>
                Êtes-vous certain(e) de vouloir archiver
                l&apos;utilisateur(rice)?
              </p>
              <p>
                Les indicateurs créés par l&apos;utilisateur(rice) seront tout
                de même présents sur la plateforme après l&apos;archivage. Il
                est toujours possible de restaurer l&apos;utilisateur une fois
                celui-ci archivé.
              </p>
            </>
          </SimpleActionModal>
          <SimpleActionModal
            onClose={onRestoreClose}
            onConfirm={onRestoreConfirm}
            show={restoreConfirmModalShow}
            title="Confirmer la restauration"
            confirmButtonText="Restaurer l'utilisateur(rice)"
            successMessage={successRestoreMessage}
            genericErrorMessage="Des erreurs sont survenues lors de la restauration d'un compte"
            errorMessages={restoreErrors}
          >
            <>
              <p>
                Êtes-vous certain(e) de vouloir restaurer
                l&apos;utilisateur(rice)?
              </p>
              <p>
                La restauration de l&lsquo;utilisateur permet à celui-ci de se
                connecter à la plateforme de nouveau. L&lsquo;utilisateur pourra
                effectuer toutes les actions associées à son rôle dès la
                restauration terminée.
              </p>
            </>
          </SimpleActionModal>
          <ContentLoader
            data={data}
            query={LIST_USERS}
            variables={{ skip: skippedUsers, take: pageSize }}
          />
        </Col>
      </Row>
    </>
  );
}
