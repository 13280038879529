import React from 'react';
import { Col, Pagination, Row, Stack, Table } from 'react-bootstrap';
import ElementSelector from '../ElementSelector';

/**
 * La liste des paramètres permettant au composant de s'afficher
 * correctement
 *
 * @typeParam T - Le type d'élément présenté dans le tableau
 */
export interface SimpleActionTableProps<T> {
  /**
   * Le titre de chacune des colonnes du tableau
   */
  titles: string[];

  /**
   * Les éléments devant être affiché chacun sur une ligne du tableau
   */
  data: T[];

  /**
   * La disposition d'un élément sur une ligne. Chaque élément du tableau
   * constitut une colonne d'affichage à l'aide de l'élément `T` et de l'index
   * de la ligne.
   *
   * Le `ReactElement` retourné se trouvera entre des balises `<td>` pour
   * l'affichage
   */
  disposition: Array<(item: T, index: number) => React.ReactElement>;

  /**
   * Les informations sur la pagination des données. L'objet passsé doit
   * contenir les informations suivantes:
   * - Skipped : Le nombre d'élément sauté, aussi appellé `offset`.
   * - Took : Le nombre d'éléments contenu dans une page.
   * - Total : Le nombre total d'éléments accessibles sans pagination.
   *
   * La valeur est optionnelle. Si elle n'est pas configuré, le tableau ignore
   * ignore la pagination.
   */
  pageInfo?: { skipped: number; took: number; total: number };

  /**
   * La fonction de rappel lorsqu'un bouton de changement de page est cliqué
   * par un utilisateur.
   * @param pageNumber Le numéro de la page demandé
   */
  onPageSwitch?: (pageNumber: number) => void;

  /**
   * La fonction de rappel lorsque la quantité d'élément à afficher dans le tableau
   * change
   * @param pageSize La quantité d'élément à inclure dans la page
   */
  onPageSizeSwitch?: (pageSize: number) => void;

  /**
   * Les différentes tailles de page disponible
   * 
   * Valeur par défaut : 10, 20, 50, 100
   */
  pageSizeOptions?: number[]
}

/**
 * Ce composant permet la standardisation d'affichage d'un tableau au sein
 * de l'application.
 * @param props Les différents paramètres nécessaire à l'affichage du tableau
 * @returns Le tableau rendu par React
 */
export default function SimpleActionTable<T>(
  props: SimpleActionTableProps<T>
): React.ReactElement {

  const pageSizeOptions = props.pageSizeOptions ?? [10, 20, 50, 100];

  const pagination = (): React.ReactElement => {
    if (props.pageInfo != null) {
      const totalPages: number = Math.ceil(
        props.pageInfo?.total / props.pageInfo?.took
      );

      const current: number = Math.ceil(
        props.pageInfo.skipped / props.pageInfo.took + 1
      );

      const numFirstElement = props.pageInfo.skipped + 1;
      const numLastElement = Math.min(
        props.pageInfo.skipped + props.pageInfo.took,
        props.pageInfo.total
      );

      const numberedPrevious = (): React.ReactElement => {
        if (current > 1) {
          const pageNumber = current - 1;
          return (
            <Pagination.Item
              onClick={() => props.onPageSwitch?.(pageNumber) ?? undefined}
            >
              {pageNumber}
            </Pagination.Item>
          );
        }
        return <></>;
      };

      const numberedPreviousPrevious = (): React.ReactElement => {
        if (current > 2) {
          const pageNumber = current - 2;
          return (
            <Pagination.Item
              onClick={() => props.onPageSwitch?.(pageNumber) ?? undefined}
            >
              {pageNumber}
            </Pagination.Item>
          );
        }
        return <></>;
      };

      const numberedNext = (): React.ReactElement => {
        if (current < totalPages) {
          const pageNumber = current + 1;
          return (
            <Pagination.Item
              onClick={() => props.onPageSwitch?.(pageNumber) ?? undefined}
            >
              {pageNumber}
            </Pagination.Item>
          );
        }
        return <></>;
      };

      const numberedNextNext = (): React.ReactElement => {
        if (current + 1 < totalPages) {
          const pageNumber = current + 2;
          return (
            <Pagination.Item
              onClick={() => props.onPageSwitch?.(pageNumber) ?? undefined}
            >
              {pageNumber}
            </Pagination.Item>
          );
        }
        return <></>;
      };

      return (
        <>
          <Row className="justify-content-md-center">
            <Col md={'auto'}>
              <Pagination className="mb-1">
                <Pagination.First
                  disabled={current <= 1}
                  onClick={() => props.onPageSwitch?.(1) ?? undefined}
                />
                <Pagination.Prev
                  disabled={current <= 1}
                  onClick={() => props.onPageSwitch?.(current - 1) ?? undefined}
                />
                <Pagination.Ellipsis disabled />
                {numberedPreviousPrevious()}
                {numberedPrevious()}
                <Pagination.Item active>{current}</Pagination.Item>
                {numberedNext()}
                {numberedNextNext()}
                <Pagination.Ellipsis disabled />
                <Pagination.Next
                  disabled={current >= totalPages}
                  onClick={() => props.onPageSwitch?.(current + 1) ?? undefined}
                />
                <Pagination.Last
                  disabled={current >= totalPages}
                  onClick={() => props.onPageSwitch?.(totalPages) ?? undefined}
                />
              </Pagination>
            </Col>
          </Row>
          <Row className="justify-content-md-center">
            <Col md={'auto'}>
              <Stack direction="horizontal" gap={2}>
                <div className="text-muted">
                  <small>{`Page ${current} de ${totalPages} `}</small>
                </div>
                <div className="vr"></div>
                <div className="text-muted">
                  <small>{`Élément(s) ${numFirstElement}-${numLastElement} sur ${props.pageInfo.total}`}</small>
                </div>
                <div className="vr"></div>
                <div className="text-muted">
                  <small>{`Élément(s) par page`}</small>
                </div>
                <div className="d-inline-flex">
                  <ElementSelector
                    elements={pageSizeOptions.map<string>(e => e.toString())}
                    defaultElement={props.pageInfo.took.toString()}
                    onElementChange={s => {
                      props.onPageSizeSwitch?.(Number.parseInt(s));
                    }}
                    size='sm'
                  />
                </div>
              </Stack>
            </Col>
          </Row>
        </>
      );
    }
    return <></>;
  };

  return (
    <>
      <Table>
        <thead>
          <tr className="table-dark">
            {props.titles.map((title, index) => (
              <th key={`simpletable-head-${index}`}>{title}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {props.data.map((item, indexy) => (
            <tr key={`simpletable-row-${indexy}`} data-testid="simpletable-row">
              {props.disposition.map((disposition, indexx) => (
                <td key={`simpletable-${indexy}- ${indexx}`}>
                  {disposition(item, indexy)}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
      {pagination()}
    </>
  );
}
