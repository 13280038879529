import React from 'react';
import { FloatingLabel, Form } from 'react-bootstrap';
import ElementSelector, { type ElementSelectorProps } from '../ElementSelector';

/**
 * Les paramètres nécessaires à l'affichage du composant
 */
export interface FloatingSelectInputProps extends ElementSelectorProps {
  /**
   * L'identifiant unique du composant
   */
  id: string;

  /**
   * L'identifiant utilisé pour tester le composant
   */
  testId?: string;

  /**
   * Le nom donné à la boîte de séletion. Ce paramètre est affiché à l'utilisateur
   */
  label: string;

  /**
   * Le texte affiché à l'écran si l'état du composant est invalide
   *
   * Ce paramètre est optionnel
   */
  invalidFeedback?: string;

  /**
   * Un texte d'aide pouvant être affiché à l'utilisateur sous le composant
   *
   * Ce paramètre est optionnel
   */
  helpBlock?: string;

  /**
   * Les propriétés CSS à appliquer au FloatingLabel
   */
  className?: string;
}

/**
 * Ce composant permet l'affichage d'une boîte de sélection de type `FloatingLabel`
 * standardisé pour l'utilisation au sein de notre application
 * @param props Les différents paramètres nécessaire à l'affichage du composant
 * @returns Le composant rendu par React
 */
export default function FloatingSelectInput(
  props: FloatingSelectInputProps
): React.ReactElement {
  return (
    <FloatingLabel
      controlId={props.id}
      label={props.label}
      className={props.className}
    >
      <ElementSelector
        id={props.id}
        testId={props.testId}
        values={props.values}
        elements={props.elements ?? []}
        defaultElement={props.defaultElement}
        onElementChange={props.onElementChange}
        required={props.required}
      />
      {props.invalidFeedback != null && (
        <Form.Control.Feedback type="invalid">
          {props.invalidFeedback}
        </Form.Control.Feedback>
      )}
      {props.helpBlock != null && (
        <Form.Text id={`${props.id}-helpblock`} muted>
          {props.helpBlock}
        </Form.Text>
      )}
    </FloatingLabel>
  );
}
