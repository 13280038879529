/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\nmutation Login($email: String!, $password: String!) {\n  login(input: { email: $email, password: $password}) {\n    user {\n      id\n      email\n      roles\n      firstName\n      lastName\n    }\n    errors {\n      ... on InvalidLoginError {\n        message\n      }\n    }\n  } \n}\n": types.LoginDocument,
    "\nmutation Logout {\n  logout {\n    boolean\n  }\n}\n": types.LogoutDocument,
    "\nmutation ArchiveUser($userId: String!) {\n  archiveUser(input: {userId: $userId}) {\n    user {\n      id\n      state\n    }\n    errors {\n      ... on NotFoundError {\n        message\n      }\n      ... on GenericError {\n        message\n      }\n    }\n  }\n}\n": types.ArchiveUserDocument,
    "\nmutation RestoreUser($userId: String!) {\n  restoreUser(input: {userId: $userId}) {\n    user {\n      id\n      state\n    }\n    errors {\n      ... on Error {\n        message\n      }\n    }\n  }\n}\n": types.RestoreUserDocument,
    "\nmutation AddUser($firstName: String!, $lastName: String!, $email: String!, $password: String!) {\n  addUser(input: { firstName: $firstName, lastName: $lastName, email: $email, password: $password}) {\n    user {\n      id\n      email\n      roles\n      firstName\n      lastName\n    }\n    errors {\n      ... on Error {\n        message\n      }\n    }\n  } \n}\n": types.AddUserDocument,
    "\nmutation AddAttribute ($name: String!, $description: String!, $valueType: AttributeValueOption!, $mandatory: Boolean!, $unique: Boolean!,$values: [String!] ){\n  addAttribute (input: {\n    name:$name,  \n    description:$description\n    valueType: $valueType,\n    mandatory: $mandatory,\n    unique: $unique,\n    values: $values\n  }\n  ) {\n    attribute {\n      id\n      name\n      mandatory\n      unique\n      valueType\n      readableValueType\n      values {\n        id\n        value\n      }\n    }\n    errors {\n      ... on Error {\n        message\n      }\n    }\n  }\n}\n": types.AddAttributeDocument,
    "\nmutation DeleteAttribute ($attributeId: String!) {\n  deleteAttribute (input : { id : $attributeId}){\n    attribute {\n      id\n      name\n    }\n    errors {\n      ... on Error {\n        message\n      }\n    }\n  }\n}\n": types.DeleteAttributeDocument,
    "\nmutation EditAttribute ($id:String!, $name:String, $description:String, $mandatory:Boolean, $unique:Boolean, $valueType: AttributeValueOption, $values:[AttributeValueInput!]){\n  editAttribute (input: {\n    id: $id, \n    name: $name,\n    description: $description,\n    mandatory: $mandatory,\n    unique: $unique,\n    valueType : $valueType,\n    values: $values\n    }) \n  {\n    attribute {\n      id\n      name\n      description\n      mandatory\n      unique\n      valueType\n      readableValueType\n      canBeEdited\n      canBeDeleted\n      editedOn\n      values {\n        id\n        value\n        canBeDeleted\n      }\n    }\n    errors {\n      ... on Error {\n        message\n      }\n    }\n  }\n}\n": types.EditAttributeDocument,
    "\nmutation AddIndicator ($attributes: [IndicatorAttributeInput!]!) {\n  addIndicator (input: {attributes: $attributes})\n    {\n    indicator {\n      id\n    }\n    errors {\n      ... on Error {\n        message\n      }\n    }\n  }\n}\n": types.AddIndicatorDocument,
    "\nmutation DeleteIndicator ($id: String!) {\n  deleteIndicator(input: {id: $id}) {\n    indicator {\n      id\n    }\n    errors {\n      ... on Error {\n        message\n      }\n    }\n  }\n}\n": types.DeleteIndicatorDocument,
    "\nmutation EditUser ($id:String!, $firstName:String, $lastName:String, $email:String, $password:String, $passwordConfirm:String, $roles:[String!]){\n  editUser (input: {\n    userId: $id\n    firstName: $firstName\n    lastName: $lastName\n    email: $email\n    password: $password\n    passwordConfirm: $passwordConfirm\n    roles: $roles\n  })\n  {\n    user {\n      id\n      firstName\n      lastName\n      email\n      roles\n    }\n    errors {\n      ... on Error {\n        message\n      }\n    }\n  }\n}\n": types.EditUserDocument,
    "\nmutation EditIndicator ($id:String!, $attributes: [IndicatorAttributeEditInput!]!) {\n  editIndicator (input: {\n    id: $id\n    attributes: $attributes\n  }\n  ) {\n    indicator {\n      id\n      attributes {\n        id\n      }\n    }\n    errors {\n      ... on Error {\n        message\n      }\n    }\n  }\n}\n": types.EditIndicatorDocument,
    "\nmutation AddFileDocument ($fileName: String!, $title: String!, $abstract: String!, $authors: [String!], $publicationYear: Int) {\n  addFileDocument(input: {\n    fileName: $fileName,\n    title: $title,\n    abstract: $abstract,\n    authors: $authors,\n    publicationYear: $publicationYear\n  }) {\n    uploadToken {\n      token\n      uploadURL\n    }\n    errors {\n      ... on Error {\n        message\n      }\n    }\n  }\n}\n": types.AddFileDocumentDocument,
    "\nmutation AddLinkDocument ($url: String!, $title: String!, $abstract: String!, $authors: [String!], $publicationYear: Int) {\n  addLinkDocument(input: {\n    url: $url,\n    title: $title,\n    abstract: $abstract,\n    authors: $authors,\n    publicationYear: $publicationYear\n  }) {\n    document {\n      id\n    }\n    errors {\n      ... on Error {\n        message\n      }\n    }\n  }\n}\n": types.AddLinkDocumentDocument,
    "\nmutation AddExample ($indicatorId: String!, $documentId: String!, $remark: String!) {\n  addExample(input: {\n    indicatorId: $indicatorId,\n    documentId: $documentId,\n    remark: $remark\n  }) {\n    example {\n      id\n    }\n    errors {\n      ... on Error {\n        message\n      }\n    }\n  }\n}\n": types.AddExampleDocument,
    "\nquery Me {\n  user {\n    id\n    email\n    roles\n    firstName\n    lastName\n  }\n}\n": types.MeDocument,
    "\nquery ListUsers ($skip: Int! $take: Int!)  {\n  users (skip: $skip take: $take){\n    totalCount\n    items {\n      id\n      email\n      roles\n      firstName\n      lastName\n      state\n    }\n  } \n}\n": types.ListUsersDocument,
    "\nquery GetUser ($id: String!)  {\n  users (where: {id: {eq: $id}}) {\n    items {\n      id\n      email\n      roles\n      firstName\n      lastName\n      state\n      lockoutEnd\n    }\n  }\n  roles {\n    id\n    name\n  }\n}\n": types.GetUserDocument,
    "\nquery ListRoles {\n  roles {\n    id\n    name\n  }\n}\n": types.ListRolesDocument,
    "\nquery ListAttributeValueOptions {\n  attributeValueOptions {\n     readableValue\n     enumValue\n  }\n}\n": types.ListAttributeValueOptionsDocument,
    "\nquery ListAttributes ($skip: Int! $take: Int!) {\n  attributes (skip: $skip take: $take) {\n    totalCount\n    items {\n      id\n      name\n      description\n      mandatory\n      unique\n      readableValueType\n      canBeEdited\n      canBeDeleted\n      owner {\n        id\n        firstName\n        lastName\n        email\n      }\n    }\n  }\n}\n": types.ListAttributesDocument,
    "\nquery ReadAttribute ($attributeId: String!) {\n  attributes (where: {id: {eq: $attributeId}}) {\n    items {\n      id\n      name\n      description\n      mandatory\n      unique\n      valueType\n      readableValueType\n      createdOn\n      editedOn\n      canBeEdited\n      canBeDeleted\n      numberOfLinkedIndicators\n      values {\n        id\n        value\n        canBeDeleted\n      }\n      owner {\n        firstName\n        lastName\n        email\n      }\n    }\n  }\n}\n": types.ReadAttributeDocument,
    "\nquery ListAttributesWithFilterOperations ($name: String!, $selected: [String]) {\n  attributes (where: { name: { contains: $name }, and: { id: { nin: $selected } } }, take: 10) {\n    items {\n      id\n      name\n      acceptedIndicatorFilterOperations {\n        enumValue\n        readableValue\n        negatedReadableValue\n      }\n    }\n  }\n}\n": types.ListAttributesWithFilterOperationsDocument,
    "\nquery ListIndicatorsWithMandatoryAttributes ($skip: Int!, $take: Int!, $search: IndicatorSearchInput) {\n  indicators (skip: $skip, take: $take, search: $search){\n    totalCount\n    items {\n      id\n      canBeEdited\n      attributes {\n        primaryValue\n        referenceValue {\n          value\n        }\n        attribute {\n          id\n          name\n        }\n      }\n    }\n  }\n  attributes (where: { mandatory : { eq : true}}) {\n    totalCount\n    items {\n      id\n      name\n      mandatory\n    }\n  }\n}\n": types.ListIndicatorsWithMandatoryAttributesDocument,
    "\nquery ListMandatoryAndNotMandatoryAttributes {\n  mandatory : attributes (where: { mandatory: {eq: true}}) {\n    items {\n      id\n      name\n      mandatory\n    }\n  }\n  optional : attributes (where: { mandatory: {eq: false}}) {\n    items {\n      id\n      name\n      mandatory\n    }\n  }\n}\n": types.ListMandatoryAndNotMandatoryAttributesDocument,
    "\nquery ReadIndicator ($indicatorId: String!) {\n  indicators (where: {id: {eq: $indicatorId}}){\n    items {\n      id\n      createdOn\n      editedOn\n      owner {\n        id\n        firstName\n        lastName\n        email\n      }\n      attributes {\n        id\n        primaryValue\n        referenceValue {\n          id\n          value\n        }\n        attribute {\n          id\n          name\n          description\n          valueType\n          mandatory\n          readableValueType\n        }\n      }\n    }\n  }\n}\n": types.ReadIndicatorDocument,
};

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function gql(source: string): unknown;

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation Login($email: String!, $password: String!) {\n  login(input: { email: $email, password: $password}) {\n    user {\n      id\n      email\n      roles\n      firstName\n      lastName\n    }\n    errors {\n      ... on InvalidLoginError {\n        message\n      }\n    }\n  } \n}\n"): (typeof documents)["\nmutation Login($email: String!, $password: String!) {\n  login(input: { email: $email, password: $password}) {\n    user {\n      id\n      email\n      roles\n      firstName\n      lastName\n    }\n    errors {\n      ... on InvalidLoginError {\n        message\n      }\n    }\n  } \n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation Logout {\n  logout {\n    boolean\n  }\n}\n"): (typeof documents)["\nmutation Logout {\n  logout {\n    boolean\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation ArchiveUser($userId: String!) {\n  archiveUser(input: {userId: $userId}) {\n    user {\n      id\n      state\n    }\n    errors {\n      ... on NotFoundError {\n        message\n      }\n      ... on GenericError {\n        message\n      }\n    }\n  }\n}\n"): (typeof documents)["\nmutation ArchiveUser($userId: String!) {\n  archiveUser(input: {userId: $userId}) {\n    user {\n      id\n      state\n    }\n    errors {\n      ... on NotFoundError {\n        message\n      }\n      ... on GenericError {\n        message\n      }\n    }\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation RestoreUser($userId: String!) {\n  restoreUser(input: {userId: $userId}) {\n    user {\n      id\n      state\n    }\n    errors {\n      ... on Error {\n        message\n      }\n    }\n  }\n}\n"): (typeof documents)["\nmutation RestoreUser($userId: String!) {\n  restoreUser(input: {userId: $userId}) {\n    user {\n      id\n      state\n    }\n    errors {\n      ... on Error {\n        message\n      }\n    }\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation AddUser($firstName: String!, $lastName: String!, $email: String!, $password: String!) {\n  addUser(input: { firstName: $firstName, lastName: $lastName, email: $email, password: $password}) {\n    user {\n      id\n      email\n      roles\n      firstName\n      lastName\n    }\n    errors {\n      ... on Error {\n        message\n      }\n    }\n  } \n}\n"): (typeof documents)["\nmutation AddUser($firstName: String!, $lastName: String!, $email: String!, $password: String!) {\n  addUser(input: { firstName: $firstName, lastName: $lastName, email: $email, password: $password}) {\n    user {\n      id\n      email\n      roles\n      firstName\n      lastName\n    }\n    errors {\n      ... on Error {\n        message\n      }\n    }\n  } \n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation AddAttribute ($name: String!, $description: String!, $valueType: AttributeValueOption!, $mandatory: Boolean!, $unique: Boolean!,$values: [String!] ){\n  addAttribute (input: {\n    name:$name,  \n    description:$description\n    valueType: $valueType,\n    mandatory: $mandatory,\n    unique: $unique,\n    values: $values\n  }\n  ) {\n    attribute {\n      id\n      name\n      mandatory\n      unique\n      valueType\n      readableValueType\n      values {\n        id\n        value\n      }\n    }\n    errors {\n      ... on Error {\n        message\n      }\n    }\n  }\n}\n"): (typeof documents)["\nmutation AddAttribute ($name: String!, $description: String!, $valueType: AttributeValueOption!, $mandatory: Boolean!, $unique: Boolean!,$values: [String!] ){\n  addAttribute (input: {\n    name:$name,  \n    description:$description\n    valueType: $valueType,\n    mandatory: $mandatory,\n    unique: $unique,\n    values: $values\n  }\n  ) {\n    attribute {\n      id\n      name\n      mandatory\n      unique\n      valueType\n      readableValueType\n      values {\n        id\n        value\n      }\n    }\n    errors {\n      ... on Error {\n        message\n      }\n    }\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation DeleteAttribute ($attributeId: String!) {\n  deleteAttribute (input : { id : $attributeId}){\n    attribute {\n      id\n      name\n    }\n    errors {\n      ... on Error {\n        message\n      }\n    }\n  }\n}\n"): (typeof documents)["\nmutation DeleteAttribute ($attributeId: String!) {\n  deleteAttribute (input : { id : $attributeId}){\n    attribute {\n      id\n      name\n    }\n    errors {\n      ... on Error {\n        message\n      }\n    }\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation EditAttribute ($id:String!, $name:String, $description:String, $mandatory:Boolean, $unique:Boolean, $valueType: AttributeValueOption, $values:[AttributeValueInput!]){\n  editAttribute (input: {\n    id: $id, \n    name: $name,\n    description: $description,\n    mandatory: $mandatory,\n    unique: $unique,\n    valueType : $valueType,\n    values: $values\n    }) \n  {\n    attribute {\n      id\n      name\n      description\n      mandatory\n      unique\n      valueType\n      readableValueType\n      canBeEdited\n      canBeDeleted\n      editedOn\n      values {\n        id\n        value\n        canBeDeleted\n      }\n    }\n    errors {\n      ... on Error {\n        message\n      }\n    }\n  }\n}\n"): (typeof documents)["\nmutation EditAttribute ($id:String!, $name:String, $description:String, $mandatory:Boolean, $unique:Boolean, $valueType: AttributeValueOption, $values:[AttributeValueInput!]){\n  editAttribute (input: {\n    id: $id, \n    name: $name,\n    description: $description,\n    mandatory: $mandatory,\n    unique: $unique,\n    valueType : $valueType,\n    values: $values\n    }) \n  {\n    attribute {\n      id\n      name\n      description\n      mandatory\n      unique\n      valueType\n      readableValueType\n      canBeEdited\n      canBeDeleted\n      editedOn\n      values {\n        id\n        value\n        canBeDeleted\n      }\n    }\n    errors {\n      ... on Error {\n        message\n      }\n    }\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation AddIndicator ($attributes: [IndicatorAttributeInput!]!) {\n  addIndicator (input: {attributes: $attributes})\n    {\n    indicator {\n      id\n    }\n    errors {\n      ... on Error {\n        message\n      }\n    }\n  }\n}\n"): (typeof documents)["\nmutation AddIndicator ($attributes: [IndicatorAttributeInput!]!) {\n  addIndicator (input: {attributes: $attributes})\n    {\n    indicator {\n      id\n    }\n    errors {\n      ... on Error {\n        message\n      }\n    }\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation DeleteIndicator ($id: String!) {\n  deleteIndicator(input: {id: $id}) {\n    indicator {\n      id\n    }\n    errors {\n      ... on Error {\n        message\n      }\n    }\n  }\n}\n"): (typeof documents)["\nmutation DeleteIndicator ($id: String!) {\n  deleteIndicator(input: {id: $id}) {\n    indicator {\n      id\n    }\n    errors {\n      ... on Error {\n        message\n      }\n    }\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation EditUser ($id:String!, $firstName:String, $lastName:String, $email:String, $password:String, $passwordConfirm:String, $roles:[String!]){\n  editUser (input: {\n    userId: $id\n    firstName: $firstName\n    lastName: $lastName\n    email: $email\n    password: $password\n    passwordConfirm: $passwordConfirm\n    roles: $roles\n  })\n  {\n    user {\n      id\n      firstName\n      lastName\n      email\n      roles\n    }\n    errors {\n      ... on Error {\n        message\n      }\n    }\n  }\n}\n"): (typeof documents)["\nmutation EditUser ($id:String!, $firstName:String, $lastName:String, $email:String, $password:String, $passwordConfirm:String, $roles:[String!]){\n  editUser (input: {\n    userId: $id\n    firstName: $firstName\n    lastName: $lastName\n    email: $email\n    password: $password\n    passwordConfirm: $passwordConfirm\n    roles: $roles\n  })\n  {\n    user {\n      id\n      firstName\n      lastName\n      email\n      roles\n    }\n    errors {\n      ... on Error {\n        message\n      }\n    }\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation EditIndicator ($id:String!, $attributes: [IndicatorAttributeEditInput!]!) {\n  editIndicator (input: {\n    id: $id\n    attributes: $attributes\n  }\n  ) {\n    indicator {\n      id\n      attributes {\n        id\n      }\n    }\n    errors {\n      ... on Error {\n        message\n      }\n    }\n  }\n}\n"): (typeof documents)["\nmutation EditIndicator ($id:String!, $attributes: [IndicatorAttributeEditInput!]!) {\n  editIndicator (input: {\n    id: $id\n    attributes: $attributes\n  }\n  ) {\n    indicator {\n      id\n      attributes {\n        id\n      }\n    }\n    errors {\n      ... on Error {\n        message\n      }\n    }\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation AddFileDocument ($fileName: String!, $title: String!, $abstract: String!, $authors: [String!], $publicationYear: Int) {\n  addFileDocument(input: {\n    fileName: $fileName,\n    title: $title,\n    abstract: $abstract,\n    authors: $authors,\n    publicationYear: $publicationYear\n  }) {\n    uploadToken {\n      token\n      uploadURL\n    }\n    errors {\n      ... on Error {\n        message\n      }\n    }\n  }\n}\n"): (typeof documents)["\nmutation AddFileDocument ($fileName: String!, $title: String!, $abstract: String!, $authors: [String!], $publicationYear: Int) {\n  addFileDocument(input: {\n    fileName: $fileName,\n    title: $title,\n    abstract: $abstract,\n    authors: $authors,\n    publicationYear: $publicationYear\n  }) {\n    uploadToken {\n      token\n      uploadURL\n    }\n    errors {\n      ... on Error {\n        message\n      }\n    }\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation AddLinkDocument ($url: String!, $title: String!, $abstract: String!, $authors: [String!], $publicationYear: Int) {\n  addLinkDocument(input: {\n    url: $url,\n    title: $title,\n    abstract: $abstract,\n    authors: $authors,\n    publicationYear: $publicationYear\n  }) {\n    document {\n      id\n    }\n    errors {\n      ... on Error {\n        message\n      }\n    }\n  }\n}\n"): (typeof documents)["\nmutation AddLinkDocument ($url: String!, $title: String!, $abstract: String!, $authors: [String!], $publicationYear: Int) {\n  addLinkDocument(input: {\n    url: $url,\n    title: $title,\n    abstract: $abstract,\n    authors: $authors,\n    publicationYear: $publicationYear\n  }) {\n    document {\n      id\n    }\n    errors {\n      ... on Error {\n        message\n      }\n    }\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation AddExample ($indicatorId: String!, $documentId: String!, $remark: String!) {\n  addExample(input: {\n    indicatorId: $indicatorId,\n    documentId: $documentId,\n    remark: $remark\n  }) {\n    example {\n      id\n    }\n    errors {\n      ... on Error {\n        message\n      }\n    }\n  }\n}\n"): (typeof documents)["\nmutation AddExample ($indicatorId: String!, $documentId: String!, $remark: String!) {\n  addExample(input: {\n    indicatorId: $indicatorId,\n    documentId: $documentId,\n    remark: $remark\n  }) {\n    example {\n      id\n    }\n    errors {\n      ... on Error {\n        message\n      }\n    }\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery Me {\n  user {\n    id\n    email\n    roles\n    firstName\n    lastName\n  }\n}\n"): (typeof documents)["\nquery Me {\n  user {\n    id\n    email\n    roles\n    firstName\n    lastName\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery ListUsers ($skip: Int! $take: Int!)  {\n  users (skip: $skip take: $take){\n    totalCount\n    items {\n      id\n      email\n      roles\n      firstName\n      lastName\n      state\n    }\n  } \n}\n"): (typeof documents)["\nquery ListUsers ($skip: Int! $take: Int!)  {\n  users (skip: $skip take: $take){\n    totalCount\n    items {\n      id\n      email\n      roles\n      firstName\n      lastName\n      state\n    }\n  } \n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery GetUser ($id: String!)  {\n  users (where: {id: {eq: $id}}) {\n    items {\n      id\n      email\n      roles\n      firstName\n      lastName\n      state\n      lockoutEnd\n    }\n  }\n  roles {\n    id\n    name\n  }\n}\n"): (typeof documents)["\nquery GetUser ($id: String!)  {\n  users (where: {id: {eq: $id}}) {\n    items {\n      id\n      email\n      roles\n      firstName\n      lastName\n      state\n      lockoutEnd\n    }\n  }\n  roles {\n    id\n    name\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery ListRoles {\n  roles {\n    id\n    name\n  }\n}\n"): (typeof documents)["\nquery ListRoles {\n  roles {\n    id\n    name\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery ListAttributeValueOptions {\n  attributeValueOptions {\n     readableValue\n     enumValue\n  }\n}\n"): (typeof documents)["\nquery ListAttributeValueOptions {\n  attributeValueOptions {\n     readableValue\n     enumValue\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery ListAttributes ($skip: Int! $take: Int!) {\n  attributes (skip: $skip take: $take) {\n    totalCount\n    items {\n      id\n      name\n      description\n      mandatory\n      unique\n      readableValueType\n      canBeEdited\n      canBeDeleted\n      owner {\n        id\n        firstName\n        lastName\n        email\n      }\n    }\n  }\n}\n"): (typeof documents)["\nquery ListAttributes ($skip: Int! $take: Int!) {\n  attributes (skip: $skip take: $take) {\n    totalCount\n    items {\n      id\n      name\n      description\n      mandatory\n      unique\n      readableValueType\n      canBeEdited\n      canBeDeleted\n      owner {\n        id\n        firstName\n        lastName\n        email\n      }\n    }\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery ReadAttribute ($attributeId: String!) {\n  attributes (where: {id: {eq: $attributeId}}) {\n    items {\n      id\n      name\n      description\n      mandatory\n      unique\n      valueType\n      readableValueType\n      createdOn\n      editedOn\n      canBeEdited\n      canBeDeleted\n      numberOfLinkedIndicators\n      values {\n        id\n        value\n        canBeDeleted\n      }\n      owner {\n        firstName\n        lastName\n        email\n      }\n    }\n  }\n}\n"): (typeof documents)["\nquery ReadAttribute ($attributeId: String!) {\n  attributes (where: {id: {eq: $attributeId}}) {\n    items {\n      id\n      name\n      description\n      mandatory\n      unique\n      valueType\n      readableValueType\n      createdOn\n      editedOn\n      canBeEdited\n      canBeDeleted\n      numberOfLinkedIndicators\n      values {\n        id\n        value\n        canBeDeleted\n      }\n      owner {\n        firstName\n        lastName\n        email\n      }\n    }\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery ListAttributesWithFilterOperations ($name: String!, $selected: [String]) {\n  attributes (where: { name: { contains: $name }, and: { id: { nin: $selected } } }, take: 10) {\n    items {\n      id\n      name\n      acceptedIndicatorFilterOperations {\n        enumValue\n        readableValue\n        negatedReadableValue\n      }\n    }\n  }\n}\n"): (typeof documents)["\nquery ListAttributesWithFilterOperations ($name: String!, $selected: [String]) {\n  attributes (where: { name: { contains: $name }, and: { id: { nin: $selected } } }, take: 10) {\n    items {\n      id\n      name\n      acceptedIndicatorFilterOperations {\n        enumValue\n        readableValue\n        negatedReadableValue\n      }\n    }\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery ListIndicatorsWithMandatoryAttributes ($skip: Int!, $take: Int!, $search: IndicatorSearchInput) {\n  indicators (skip: $skip, take: $take, search: $search){\n    totalCount\n    items {\n      id\n      canBeEdited\n      attributes {\n        primaryValue\n        referenceValue {\n          value\n        }\n        attribute {\n          id\n          name\n        }\n      }\n    }\n  }\n  attributes (where: { mandatory : { eq : true}}) {\n    totalCount\n    items {\n      id\n      name\n      mandatory\n    }\n  }\n}\n"): (typeof documents)["\nquery ListIndicatorsWithMandatoryAttributes ($skip: Int!, $take: Int!, $search: IndicatorSearchInput) {\n  indicators (skip: $skip, take: $take, search: $search){\n    totalCount\n    items {\n      id\n      canBeEdited\n      attributes {\n        primaryValue\n        referenceValue {\n          value\n        }\n        attribute {\n          id\n          name\n        }\n      }\n    }\n  }\n  attributes (where: { mandatory : { eq : true}}) {\n    totalCount\n    items {\n      id\n      name\n      mandatory\n    }\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery ListMandatoryAndNotMandatoryAttributes {\n  mandatory : attributes (where: { mandatory: {eq: true}}) {\n    items {\n      id\n      name\n      mandatory\n    }\n  }\n  optional : attributes (where: { mandatory: {eq: false}}) {\n    items {\n      id\n      name\n      mandatory\n    }\n  }\n}\n"): (typeof documents)["\nquery ListMandatoryAndNotMandatoryAttributes {\n  mandatory : attributes (where: { mandatory: {eq: true}}) {\n    items {\n      id\n      name\n      mandatory\n    }\n  }\n  optional : attributes (where: { mandatory: {eq: false}}) {\n    items {\n      id\n      name\n      mandatory\n    }\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery ReadIndicator ($indicatorId: String!) {\n  indicators (where: {id: {eq: $indicatorId}}){\n    items {\n      id\n      createdOn\n      editedOn\n      owner {\n        id\n        firstName\n        lastName\n        email\n      }\n      attributes {\n        id\n        primaryValue\n        referenceValue {\n          id\n          value\n        }\n        attribute {\n          id\n          name\n          description\n          valueType\n          mandatory\n          readableValueType\n        }\n      }\n    }\n  }\n}\n"): (typeof documents)["\nquery ReadIndicator ($indicatorId: String!) {\n  indicators (where: {id: {eq: $indicatorId}}){\n    items {\n      id\n      createdOn\n      editedOn\n      owner {\n        id\n        firstName\n        lastName\n        email\n      }\n      attributes {\n        id\n        primaryValue\n        referenceValue {\n          id\n          value\n        }\n        attribute {\n          id\n          name\n          description\n          valueType\n          mandatory\n          readableValueType\n        }\n      }\n    }\n  }\n}\n"];

export function gql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;