import { gql } from '../__generated__/gql';

/**
 * Query GraphQL utilisée pour obtenir l'utilisateur authentifié
 * selon le _cookie_ d'authentification.
 *
 * Il est important d'utiliser la commande `npm run compile`
 * une fois la commande écrite pour rafraichier les types TypeScript
 * chargés dynamiquement.
 */
export const ME = gql(`
query Me {
  user {
    id
    email
    roles
    firstName
    lastName
  }
}
`);

/**
 * Query GraphQL permettant d'obtenir la liste de tous les utilisateurs du système
 */
export const LIST_USERS = gql(`
query ListUsers ($skip: Int! $take: Int!)  {
  users (skip: $skip take: $take){
    totalCount
    items {
      id
      email
      roles
      firstName
      lastName
      state
    }
  } 
}
`);

/**
 * Query GraphQL permettant d'optenir l'utilisateur par son identifiant.
 */
export const GET_USER = gql(`
query GetUser ($id: String!)  {
  users (where: {id: {eq: $id}}) {
    items {
      id
      email
      roles
      firstName
      lastName
      state
      lockoutEnd
    }
  }
  roles {
    id
    name
  }
}
`);

/**
 * Cette requête permet de récupérer la liste des rôles du système
 */
export const LIST_ROLES = gql(`
query ListRoles {
  roles {
    id
    name
  }
}
`);

/**
 * Cette requête permet de récupérer les types de valeur qui peuvent être entrée
 * dans un attribut
 */
export const LIST_ATTRIBUTE_VALUE_OPTIONS = gql(`
query ListAttributeValueOptions {
  attributeValueOptions {
     readableValue
     enumValue
  }
}
`);

/**
 * Cette requête permet de récupérer tous les attributs de l'application
 */
export const LIST_ATTRIBUTES = gql(`
query ListAttributes ($skip: Int! $take: Int!) {
  attributes (skip: $skip take: $take) {
    totalCount
    items {
      id
      name
      description
      mandatory
      unique
      readableValueType
      canBeEdited
      canBeDeleted
      owner {
        id
        firstName
        lastName
        email
      }
    }
  }
}
`);

/**
 * Cette requête permet de récupérer un attribut avec plus de détail
 */
export const READ_ATTRIBUTE = gql(`
query ReadAttribute ($attributeId: String!) {
  attributes (where: {id: {eq: $attributeId}}) {
    items {
      id
      name
      description
      mandatory
      unique
      valueType
      readableValueType
      createdOn
      editedOn
      canBeEdited
      canBeDeleted
      numberOfLinkedIndicators
      values {
        id
        value
        canBeDeleted
      }
      owner {
        firstName
        lastName
        email
      }
    }
  }
}
`);

/**
 * Cette requête permet de récupérer les attributs et leurs informations nécessaires pour
 * appliquer une opération de filtre.
 */
export const LIST_ATTRIBUTES_WITH_FILTER_OPERATIONS = gql(`
query ListAttributesWithFilterOperations ($name: String!, $selected: [String]) {
  attributes (where: { name: { contains: $name }, and: { id: { nin: $selected } } }, take: 10) {
    items {
      id
      name
      acceptedIndicatorFilterOperations {
        enumValue
        readableValue
        negatedReadableValue
      }
    }
  }
}
`);

/**
 * Cette requête permet de récupérer les indicateurs de l'application ainsi
 * que tous les attributs obligatoires au sein de la même requête.
 *
 * Cette requête accepte, de manière optionelle, une opération de recherche pour
 * filtrer les indicateurs.
 */
export const LIST_INDICATORS_WITH_MANDATORY_ATTRIBUTE = gql(`
query ListIndicatorsWithMandatoryAttributes ($skip: Int!, $take: Int!, $search: IndicatorSearchInput) {
  indicators (skip: $skip, take: $take, search: $search){
    totalCount
    items {
      id
      canBeEdited
      attributes {
        primaryValue
        referenceValue {
          value
        }
        attribute {
          id
          name
        }
      }
    }
  }
  attributes (where: { mandatory : { eq : true}}) {
    totalCount
    items {
      id
      name
      mandatory
    }
  }
}
`);

/**
 * Cette requête GraphQL permet de récupérer, au sein d'une même requête,
 * les attributs obligatoires et les attributs optionnels à la création ou
 * modification d'un indicateur.
 */
export const LIST_MANDATORY_AND_NOT_MANDATORY_ATTRIBUTES = gql(`
query ListMandatoryAndNotMandatoryAttributes {
  mandatory : attributes (where: { mandatory: {eq: true}}) {
    items {
      id
      name
      mandatory
    }
  }
  optional : attributes (where: { mandatory: {eq: false}}) {
    items {
      id
      name
      mandatory
    }
  }
}
`);

/**
 * Cette requête permet de récupérer un indicateur avec tout ses détails
 */
export const READ_INDICATOR = gql(`
query ReadIndicator ($indicatorId: String!) {
  indicators (where: {id: {eq: $indicatorId}}){
    items {
      id
      createdOn
      editedOn
      owner {
        id
        firstName
        lastName
        email
      }
      attributes {
        id
        primaryValue
        referenceValue {
          id
          value
        }
        attribute {
          id
          name
          description
          valueType
          mandatory
          readableValueType
        }
      }
    }
  }
}
`);
