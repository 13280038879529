import { useMutation, useQuery } from '@apollo/client';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { GET_USER } from '../../graphql/queries';
import { Col, Form, Row } from 'react-bootstrap';
import { ButtonLoading } from '../../Components/ButtonLoading';
import FloatingTextInput from '../../Components/FloatingTextInput';
import type { Role } from '../../__generated__/graphql';
import { EDIT_USER } from '../../graphql/mutations';
import SavingStatus from '../../Components/SavingStatus';
import AggregateErrors from '../../utils/AggregateErrors';
import { ReturnLayout } from '../../Components/ReturnLayout';

/**
 * Ce composant permet la modification d'un utilisateur. Ce
 * composant est disponible à partir de l'URL `/administration/:id/modification`
 * @returns Le composant rendu par React
 */
export function EditUser(): React.ReactElement {
  // Récupération des informations de l'utilisateur
  const { id } = useParams();

  useQuery(GET_USER, {
    variables: { id: id ?? '' },
    onCompleted(data) {
      if (data.users?.items?.length === 1) {
        const user = data.users?.items?.at(0);
        const roles = data.roles;
        setFirstName(user?.firstName ?? '');
        setLastName(user?.lastName ?? '');
        setEmail(user?.email ?? '');
        setRoles(user?.roles ?? []);
        setAllRoles(roles);
      }
    },
  });

  const [editUser, { loading, data, error }] = useMutation(EDIT_USER, {
    onError: e => {
      console.error(e.message);
    },
  });
  const errors = AggregateErrors(error, data?.editUser.errors);
  const isComplete = data !== undefined || error !== undefined;

  // Définition des champs du formulaire
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [roles, setRoles] = useState<string[]>([]);
  const [allRoles, setAllRoles] = useState<Role[]>([]);
  const [validated, setValidated] = useState(false);
  const [updateKey, setUpdateKey] = useState(0);
  const navigate = useNavigate();

  // Définition des événements de changements
  const onStringPropertyChange = (
    setter: React.Dispatch<React.SetStateAction<string>>
  ): ((event: React.ChangeEvent<HTMLInputElement>) => void) => {
    return e => {
      setter(e.target.value);
    };
  };

  // Logique de la fonction brisée
  const onRolesChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (event.target.checked) {
      // Ajouter le rôle dans la liste
      const tmp = Object.assign([], roles);
      tmp.push(event.target.name);
      setRoles(tmp);
    } else {
      // Retirer le rôle de la liste
      const tmp = Object.assign([], roles);
      const i = tmp.findIndex(e => e === event.target.name);
      tmp.splice(i, 1);
      setRoles(tmp);
    }
  };

  const onFormSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    setUpdateKey(x => x + 1);
    if (!loading) {
      // Peut-etre configurer la password à null
      editUser({
        variables: {
          id: id ?? '',
          firstName,
          lastName,
          email,
          password: password === '' ? null : password,
          passwordConfirm: password === '' ? null : confirmPassword,
          roles,
        },
      }).then(result => {
        if (result.errors == null && result.data?.editUser.errors == null) {
          navigate('/administration');
        }
      });
    }
    setValidated(true);
    // Faire la requête
  };

  // Définition de l'affichage
  // On doit attendre que la fonction retourne OU obtenir tout dans la même requête
  const displayRolesCheckbox = (): React.ReactElement => {
    const checkboxes = allRoles.map<React.ReactElement>((e, i) => {
      return (
        <Form.Check
          className="ms-3"
          key={e.id}
          type="checkbox"
          label={e.name}
          name={e.name ?? ''}
          defaultChecked={roles.includes(e.name ?? '')}
          onChange={onRolesChange}
          data-testid={`chk-role-${e.name}`}
        />
      );
    });
    return (
      <Form.Group className="mb-3">
        <Form.Label>Rôles</Form.Label>
        {checkboxes}
      </Form.Group>
    );
  };

  return (
    <ReturnLayout href="/administration">
      <Row>
        <Col>
          <div className="h2">Modifier un utilisateur</div>
        </Col>
      </Row>
      <Row>
        {/* Mettre le formulaire ici */}
        <Form onSubmit={onFormSubmit} validated={validated}>
          <FloatingTextInput
            label="Prénom"
            required={true}
            value={firstName}
            onChange={onStringPropertyChange(setFirstName)}
            id="form-firstname"
            className='mb-3'
          />
          <FloatingTextInput
            label="Nom de famille"
            required={true}
            value={lastName}
            onChange={onStringPropertyChange(setLastName)}
            id="form-lastname"
            className='mb-3'
          />
          <FloatingTextInput
            label="Courriel"
            required={true}
            value={email}
            onChange={onStringPropertyChange(setEmail)}
            id="form-email"
            type="email"
            className='mb-3'
          />
          <FloatingTextInput
            label="Mot de passe"
            required={false}
            value={password}
            onChange={onStringPropertyChange(setPassword)}
            id="form-password"
            type="password"
            className='mb-3'
          />
          <FloatingTextInput
            label="Confirmation de mot de passe"
            required={false}
            value={confirmPassword}
            onChange={onStringPropertyChange(setConfirmPassword)}
            id="form-confirm-password"
            type="password"
            className='mb-3'
          />
          {displayRolesCheckbox()}
          <Row>
            <Col md="8">
              <SavingStatus
                complete={isComplete}
                errors={errors}
                loading={loading}
                key={updateKey}
              />
            </Col>
            <Col md="4">
              <div className="d-grid gap-2">
                <ButtonLoading
                  type="submit"
                  variant="success"
                  loading={loading}
                >
                  Enregistrer
                </ButtonLoading>
              </div>
            </Col>
          </Row>
        </Form>
      </Row>
    </ReturnLayout>
  );
}
