import React, { useEffect, useState } from 'react';
import {
  Alert,
  Button,
  Col,
  Form,
  OverlayTrigger,
  Row,
  Tooltip,
} from 'react-bootstrap';
import FloatingTextInput from '../../Components/FloatingTextInput';
import SimpleActionTable from '../../Components/SimpleActionTable';
import { ButtonLoading } from '../../Components/ButtonLoading';
import SavingStatus from '../../Components/SavingStatus';
import ContentLoader from '../../Components/ContentLoader';
import { LIST_ATTRIBUTE_VALUE_OPTIONS, ME } from '../../graphql/queries';
import { v4 as uuidv4 } from 'uuid';
import type {
  AttributeValue,
  AttributeValueOption,
  ListAttributeValueOptionsQuery,
} from '../../__generated__/graphql';
import { useQuery } from '@apollo/client';
import type { OverlayDelay } from 'react-bootstrap/esm/OverlayTrigger';
import { Plus, QuestionCircleFill, Trash3Fill } from 'react-bootstrap-icons';
import SimpleTooltip from '../SimpleTooltip';
import FloatingSelectInput from '../FloatingSelectInput';

/**
 * Les paramètres nécessaires à l'affichage et l'uitlisation du composant
 */
export interface AttributeFormProps {
  /**
   * Les données utilisées pour initialiser le formulaire
   *
   * Valeur par défaut : Chaque propriété est initialisée à un valeur vide ou `false`
   */
  formData?: FormData;

  /**
   * Action à prendre lorsque le formulaire est soumis
   * @param event L'événement de soumission du formulaire
   * @param data Les données générées par le formulaire
   * @param setValidated Confirmation de validation du formulaire
   */
  onFormSubmit: (
    event: React.FormEvent<HTMLFormElement>,
    data: FormData,
    setValidated: React.Dispatch<React.SetStateAction<boolean>>
  ) => void;

  /**
   * Indique si l'opération effectuée par le formulaire (donc la fonction
   * onFormSubmit) est complétée. Permet de gérer l'affichage des erreurs et
   * de la confirmation.
   */
  isComplete: boolean;

  /**
   * Les erreurs générées par l'envoi du formulaire
   */
  errors: string[];

  /**
   * Si le formulaire est en cours de traitement
   */
  loading: boolean;
}

/**
 * Les données utilisées par le formulaire
 */
export interface FormData {
  /**
   * Le nom de l'attribut
   */
  name?: string;

  /**
   * La description de l'attribut
   */
  description?: string;

  /**
   * Indique si l'utilisation de l'attribut est obligatoire
   */
  mandatory?: boolean;

  /**
   * Indique si les valeurs que peut prendre cet attribut doivent
   * être uniques.
   */
  unique?: boolean;

  /**
   * Le type de valeur contenu dans l'attribut
   */
  valueType?: AttributeValueOption;

  /**
   * Les valeurs que peuvent prendre l'attribut
   */
  values?: AttributeValue[] | null;
}

/**
 * Ce composant permet d'obtenir des informations sur un attribut au moyen
 * d'un formulaire.
 * @returns Le composant rendu par React
 */
export default function AttributeForm(
  props: AttributeFormProps
): React.ReactElement {
  const [updateKey, setUpdateKey] = useState(0);
  const data = useQuery(ME);
  const [name, setName] = useState(props.formData?.name ?? '');
  const [description, setDescription] = useState(
    props.formData?.description ?? ''
  );
  const [mandatory, setMandatory] = useState(
    props.formData?.mandatory ?? false
  );
  const [unique, setUnique] = useState(props.formData?.unique ?? false);
  const [valueType, setValueType] = useState<AttributeValueOption | undefined>(
    props.formData?.valueType
  );
  const [values, setValues] = useState<AttributeValue[]>(
    props.formData?.values ?? []
  );

  useEffect(() => {
    setName(props.formData?.name ?? '');
    setDescription(props.formData?.description ?? '');
    setMandatory(props.formData?.mandatory ?? false);
    setUnique(props.formData?.unique ?? false);
    setValueType(props.formData?.valueType);
    setValues(props.formData?.values ?? []);
  }, [props.formData]);

  const [validated, setValidated] = useState(false);

  const onNameChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setName(event.target.value);
  };

  const onDescriptionChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setDescription(event.target.value);
  };

  const onMandatoryChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setMandatory(x => !x);
  };

  const onUniqueChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setUnique(x => !x);
  };

  const onValueTypeChange = (element: string): void => {
    setValueType(element as AttributeValueOption);
  };

  const OptionsSelector = (
    data: ListAttributeValueOptionsQuery | undefined
  ): React.ReactElement => {
    if (data == null) return <></>;
    const optionsEnum = data.attributeValueOptions.map(
      value => value.enumValue
    );
    const optionsReadable = data.attributeValueOptions.map(
      value => value.readableValue
    );
    return (
      <>
        <FloatingSelectInput
          id='attribute-dropdown'
          className='mb-3'
          label="Type d'attribut"
          defaultElement={valueType ?? ''}
          elements={optionsReadable}
          values={optionsEnum}
          onElementChange={onValueTypeChange}
          required={true}
          invalidFeedback="Le type d'attribut est obligatoire"
        />
      </>
    );
  };

  const onListValuesAdd = (
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    setValues(list => {
      const tmp = [...list];
      tmp.push({ id: uuidv4(), value: '', canBeDeleted: true });
      return tmp;
    });
  };

  const onListValuesRemove = (
    event: React.MouseEvent<HTMLButtonElement>,
    element: AttributeValue
  ): void => {
    setValues(list => {
      const tmp = [...list];
      const item = tmp.findIndex(value => value.id === element.id);
      tmp.splice(item, 1);
      return tmp;
    });
  };

  const onTextValueChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    element: AttributeValue
  ): void => {
    const data = [...values];
    // const data = values.map(i => );
    const newItem = {
      id: element.id,
      value: event.target.value,
      canBeDeleted: element.canBeDeleted,
    };
    const item = data.findIndex(value => value.id === element.id);
    data.splice(item, 1, newItem);
    // data[item].value = event.target.value;
    setValues(data);
  };

  const disposition: Array<
    (item: AttributeValue, index: number) => React.ReactElement
  > = [
    (item, index) => (
      <Form.Control
        type="text"
        data-testid={`value-${index}`}
        value={item.value}
        onChange={e => {
          onTextValueChange(e as React.ChangeEvent<HTMLInputElement>, item);
        }}
        required
      />
    ),
    (item, index) => (
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      <Row>
        <SimpleTooltip
          text={
            !item.canBeDeleted
              ? "Cette valeur d'attribut ne peut pas être supprimé puisqu'elle est utilisé par au moins un indicateur"
              : "Supprimer la valeur d'attribut"
          }
        >
          <Col md="auto">
            <Button
              variant="danger"
              data-testid={`rm-value-${index}`}
              size="sm"
              disabled={!item.canBeDeleted}
              onClick={e => {
                onListValuesRemove(e, item);
              }}
            >
              <Trash3Fill size={20} />
            </Button>
          </Col>
        </SimpleTooltip>
      </Row>
    ),
  ];

  const overlayDelay: OverlayDelay = { show: 500, hide: 250 };

  const isAdministrator =
    data.data?.user?.roles.includes('Administrator') ?? false;

  const onFormSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    setUpdateKey(x => x + 1);
    props.onFormSubmit(
      event,
      { name, description, mandatory, unique, valueType, values },
      setValidated
    );
  };

  return (
    <Form onSubmit={onFormSubmit} validated={validated} noValidate>
      <FloatingTextInput
        id="attribute-name"
        testId="attribute-name"
        label="Nom"
        required={true}
        value={name}
        onChange={onNameChange}
        invalidFeedback="Le nom de l'attribut est obligatoire"
        className='mb-3'
      />
      <FloatingTextInput
        id="attribute-description"
        testId="attribute-description"
        label="Description"
        required={true}
        value={description}
        onChange={onDescriptionChange}
        invalidFeedback="La description de l'attribut est obligatoire"
        className='mb-3'
      />
      {isAdministrator && (
        <Row>
          <Col md={'auto'}>
            <Form.Check
              inline
              className="mb-3 me-2"
              type="switch"
              id="attribute-mandatory"
              data-testid="attribute-mandatory"
              label="Obligatoire"
              checked={mandatory}
              onChange={onMandatoryChange}
            />
            <OverlayTrigger
              placement="right"
              delay={overlayDelay}
              overlay={
                <Tooltip id="mandatoryTooltip">
                  Indique si l&apos;attribut est obligatoire
                </Tooltip>
              }
            >
              <QuestionCircleFill color="gray" />
            </OverlayTrigger>
          </Col>
          <Col md={'auto'}>
            <Form.Check
              inline
              className="mb-3 me-2"
              type="switch"
              id="attribute-unique"
              data-testid="attribute-unique"
              label="Unique"
              checked={unique}
              onChange={onUniqueChange}
            />
            <OverlayTrigger
              placement="right"
              delay={overlayDelay}
              overlay={
                <Tooltip id="uniqueTooltip">
                  Indique si les valeurs de l&apos;attribut doivent être uniques
                </Tooltip>
              }
            >
              <QuestionCircleFill color="gray" />
            </OverlayTrigger>
          </Col>
        </Row>
      )}
      <ContentLoader
        query={LIST_ATTRIBUTE_VALUE_OPTIONS}
        data={OptionsSelector}
      />
      {valueType === 'LIST' && (
        <Alert variant="light">
          <SimpleActionTable
            titles={['Valeur', 'Actions']}
            data={values}
            disposition={disposition}
          />
          <Button
            variant="primary"
            onClick={onListValuesAdd}
            className="mb-3"
            data-testid="add-attribute-value"
          >
            <Plus size={25} />
          </Button>
        </Alert>
      )}
      <Row>
        <Col md="8">
          <SavingStatus
            complete={props.isComplete}
            errors={props.errors}
            loading={props.loading}
            key={updateKey}
          />
        </Col>
        <Col md="4">
          <div className="d-grid gap-2">
            <ButtonLoading type="submit" variant="success" loading={false}>
              Enregistrer
            </ButtonLoading>
          </div>
        </Col>
      </Row>
    </Form>
  );
}
