import React from 'react';
import { useRouteError } from 'react-router-dom';

/**
 * Vue représentant une erreur lors du traitement d'une requête
 * @returns
 */
export default function ErrorPage(): React.ReactElement {
  const error = useRouteError();

  return (
    <div id="error-page">
      <h1>Oops!</h1>
      <p>Désolé, une erreur est survenue!</p>
      <p>
        <i>{error instanceof Error ? error.message : 'Erreur inconnue'}</i>
      </p>
    </div>
  );
}
