import React, { useState } from 'react';
import ContentLoader from '../../Components/ContentLoader';
import { LIST_ATTRIBUTES, ME } from '../../graphql/queries';
import type { ListAttributesQuery } from '../../__generated__/graphql';
import SimpleActionTable from '../../Components/SimpleActionTable';
import type { StandardAttribute } from '../../graphql/types';
import { ButtonLoading } from '../../Components/ButtonLoading';
import { Trash3Fill, FileEarmarkFill, PencilFill } from 'react-bootstrap-icons';
import SimpleTooltip from '../../Components/SimpleTooltip';
import { DELETE_ATTRIBUTE } from '../../graphql/mutations';
import { useMutation, useQuery } from '@apollo/client';
import AggregateErrors from '../../utils/AggregateErrors';
import SimpleActionModal from '../../Components/SimpleActionModal';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Stack from 'react-bootstrap/Stack';
import Alert from 'react-bootstrap/Alert';
import { Badge } from 'react-bootstrap';

/**
 * Ce composant permet la consultation des attributs disponible dans le système
 * Il permet aussi l'ajout d'un nouvel attribut à l'aide d'un bouton
 *
 * Ce composant est directement accessible à partir de l'URL `/attributs`
 *
 * @returns Le composant rendu par React
 */
export function Attributes(): React.ReactElement {
  const { loading: meLoading, data: meData } = useQuery(ME);
  const [confirmModalShow, setConfirmModalShow] = useState(false);
  const [attributeToDelete, setAttributeToDelete] =
    useState<StandardAttribute>();
  const [successDeleteMessage, setSuccessDeleteMessage] = useState('');
  const [skippedAttributes, setSkippedAttribute] = useState(0);
  const [deleteAttribute, { loading, data, error }] = useMutation(
    DELETE_ATTRIBUTE,
    { refetchQueries: [LIST_ATTRIBUTES] }
  );

  /**
   * Le nombre d'élément contenu dans la page
   */
  const [pageSize, setPageSize] = useState(
    parseInt(localStorage.getItem('attributePageSize') ?? '20') 
  );
  const onPageSizeChange = (size: number): void => {
    localStorage.setItem('attributePageSize', size.toString());
    setPageSize(size);
  };

  /**
   * Fonction de rappel lorsqu'une personne souhaite supprimer un attribut.
   *
   * @param {string} attributeId L'attribut que l'on souhaite archiver.
   */
  const onDeleteAttribute = (attribute: StandardAttribute): void => {
    setAttributeToDelete(attribute);
    setConfirmModalShow(true);
  };

  /**
   * Fonction de rappel lorsqu'une personne annule la suppression d'un attribut.
   */
  const onClose = (): void => {
    setConfirmModalShow(false);
    setAttributeToDelete(undefined);
  };

  /**
   * Fonction de rappel lorsqu'une personne confirme la suppression d'un attribut.
   */
  const onConfirm = (): void => {
    setConfirmModalShow(false);
    deleteAttribute({
      variables: {
        attributeId: attributeToDelete?.id ?? '',
      },
      onError: () => {},
    })
      .then(result => {
        if (
          (result.data?.deleteAttribute.errors ?? []).length === 0 &&
          (result.data?.deleteAttribute.attribute?.id ?? '') !== ''
        ) {
          setSuccessDeleteMessage("L'attribut a été supprimé avec succès");
          setTimeout(() => {
            setSuccessDeleteMessage('');
          }, 3000);
        }
      })
      .finally(() => {
        setConfirmModalShow(false);
      });
  };

  const deleteErrors = AggregateErrors(error, data?.deleteAttribute.errors);

  const dispositionElement: Array<
    (item: StandardAttribute, index: number) => React.ReactElement
  > = [
    (e, i) => <div>{e.name}</div>,
    (e, i) => <div>{e.description}</div>,
    (e, i) => <div>{e.readableValueType}</div>,
    (e, i) => (
      <Stack direction="vertical" gap={1}>
        {/* Badge obligatoire */}
        {e.mandatory && <Badge bg="warning">Obligatoire</Badge>}
        {/* Badge unique */}
        {e.unique && <Badge bg="danger">Unique</Badge>}
        {/* Badge propriétaire */}
        {!meLoading && e.owner.id === meData?.user?.id && (
          <Badge bg="success">Propriétaire</Badge>
        )}
      </Stack>
    ),
    (e, i) => (
      <Stack direction="horizontal" gap={1}>
        <Button variant="primary" href={`/attributs/${e.id}`}>
          <FileEarmarkFill />
        </Button>
        <Button variant="primary" href={`/attributs/${e.id}/modification`}>
          <PencilFill />
        </Button>
        {e.canBeDeleted && (
          <SimpleTooltip text="Supprimer l'attribut">
            <ButtonLoading
              variant="danger"
              onClick={() => {
                onDeleteAttribute(e);
              }}
              loading={e.id === attributeToDelete?.id && loading}
              testid={`delete-${e.id}`}
            >
              <Trash3Fill />
            </ButtonLoading>
          </SimpleTooltip>
        )}
      </Stack>
    ),
  ];

  const onPageSwitch = (pageNumber: number): void => {
    setSkippedAttribute((pageNumber - 1) * pageSize);
  };

  const displayList = (
    data: ListAttributesQuery | undefined
  ): React.ReactElement => {
    const titles = [
      'Nom',
      'Description',
      "Type d'attribut",
      'Propriétés',
      'Actions',
    ];
    const attributes = data?.attributes?.items ?? [];
    const hasUndeletableAttribute = attributes.some(a => !a.canBeDeleted);
    return (
      <>
        {hasUndeletableAttribute && (
          <Alert variant="warning" dismissible>
            Un ou plusieurs attributs sont actuellement utilisés par des
            indicateurs. Le bouton de retrait de ces attributs est absent
            puisque ceux-ci ne peuvent être supprimées.
          </Alert>
        )}
        <SimpleActionTable<StandardAttribute>
          titles={titles}
          disposition={dispositionElement}
          data={attributes}
          key={attributes.length}
          pageInfo={{
            skipped: skippedAttributes,
            took: pageSize,
            total: data?.attributes?.totalCount ?? 0,
          }}
          onPageSwitch={onPageSwitch}
          onPageSizeSwitch={onPageSizeChange}
        />
      </>
    );
  };

  return (
    <>
      <Row className="justify-content-md-center">
        <Col md={'10'}>
          <Row className="justify-content-md-start">
            <Col md="9">
              <div className="h2">Liste des attributs</div>
            </Col>
            <Col md={'3'}>
              <div className="d-grid">
                <Button variant="success" href="/attributs/ajout">
                  Nouveau
                </Button>
              </div>
            </Col>
          </Row>
          <Row>
            <ContentLoader
              data={displayList}
              query={LIST_ATTRIBUTES}
              variables={{ skip: skippedAttributes, take: pageSize }}
            />
          </Row>
        </Col>
      </Row>
      <SimpleActionModal
        successMessage={successDeleteMessage}
        errorMessages={deleteErrors}
        genericErrorMessage="Des erreurs sont survenues lors de la suppression de l'attribut."
        show={confirmModalShow}
        onClose={onClose}
        onConfirm={onConfirm}
        title="Confirmer la suppression"
        confirmButtonText="Supprimer l'attribut"
      >
        <>
          <p>
            L&apos;action est irréversible, êtes-vous certain(e) de vouloir
            supprimer l&apos;attribut <b>{attributeToDelete?.name}</b>?
          </p>
        </>
      </SimpleActionModal>
    </>
  );
}
