import React, { Fragment, useState } from 'react';
import { Button, Col, Row, Stack, Dropdown, Alert } from 'react-bootstrap';
import ContentLoader from '../../Components/ContentLoader';
import SimpleActionTable from '../../Components/SimpleActionTable';
import {
  LIST_ATTRIBUTES_WITH_FILTER_OPERATIONS,
  LIST_INDICATORS_WITH_MANDATORY_ATTRIBUTE,
} from '../../graphql/queries';
import {
  IndicatorFilterOperationOption,
  type IndicatorSearchInput,
  type ListIndicatorsWithMandatoryAttributesQuery,
} from '../../__generated__/graphql';
import {
  type StandardIndicatorFilterOperationOptionMapping,
  type AttributeForFilter,
  type StandardIndicator,
} from '../../graphql/types';
import { ButtonLoading } from '../../Components/ButtonLoading';
import { FileEarmarkFill, PencilFill, Trash3Fill } from 'react-bootstrap-icons';
import SimpleTooltip from '../../Components/SimpleTooltip';
import { DELETE_INDICATOR } from '../../graphql/mutations';
import { useMutation, useQuery } from '@apollo/client';
import AggregateErrors from '../../utils/AggregateErrors';
import SimpleActionModal from '../../Components/SimpleActionModal';
import FloatingTextInput from '../../Components/FloatingTextInput';

import './indicators.scss';

/**
 * Interface qui fait l'association entre un attribut et les opérations de filtre supportées
 * et la valeur effective du filtre.
 *
 * L'interface est utilisée localement seulement.
 */
interface Filter {
  /**
   * L'attribut dont l'opération doit être appliquée.
   */
  attribute: AttributeForFilter;

  /**
   * L'opération sélectionnée par l'utilisateur(rice).
   */
  operation: StandardIndicatorFilterOperationOptionMapping;

  /**
   * Un indicateur de négation.
   *
   * S'il est indiqué à `true`, la valeur inverse est retournée.
   */
  negate: boolean;

  /**
   * La valeur du filtre pour cet attribut.
   */
  value: string;
}

/**
 * Ce composant permet d'afficher la liste des indicateurs présent dans l'application
 * en fonction des attributs d'indicateur obligatoires
 * @returns Le composant rendu par React
 */
export function Indicators(): React.ReactElement {
  // <- Variables d'états ->
  /**
   * Le nombre d'élément contenu dans la page
   */
  const [skippedIndicators, setSkippedIndicators] = useState(0);
  const [pageSize, setPageSize] = useState(
    parseInt(localStorage.getItem('indicatorPageSize') ?? '20')
  );
  const [confirmModalShow, setConfirmModalShow] = useState(false);
  // État pour la suppression d'un indicateur.
  const [indicatorToDelete, setIndicatorToDelete] =
    useState<StandardIndicator>();
  const [successDeleteMessage, setSuccessDeleteMessage] = useState('');
  const [filterActive, setFilterActive] = useState(false);
  const [filterShow, setFilterShow] = useState(false);
  const [attributeName, setAttributeName] = useState('');
  const [currentFilter, setCurrentFilter] = useState('');
  const [filters, setFilters] = useState<Filter[]>([]);
  const [hasFilterBeenModified, setFilterBeenModified] = useState(false);
  const [search, setSearch] = useState<IndicatorSearchInput | undefined>(
    undefined
  );

  // <- GraphQL ->
  const [deleteIndicator, { loading, data, error }] = useMutation(
    DELETE_INDICATOR,
    { refetchQueries: [LIST_INDICATORS_WITH_MANDATORY_ATTRIBUTE] }
  );
  const {
    loading: attributesLoading,
    data: attributeData,
    error: attributeError,
  } = useQuery(LIST_ATTRIBUTES_WITH_FILTER_OPERATIONS, {
    variables: {
      name: attributeName,
      selected: filters.map(f => f.attribute.id),
    },
  });

  // Variables pour la suppression d'un indicateur
  const deleteErrors = AggregateErrors(error, data?.deleteIndicator.errors);

  // <- Fonctions de rappels ->

  const onPageSizeChange = (size: number): void => {
    localStorage.setItem('indicatorPageSize', size.toString());
    setPageSize(size);
  };

  const onPageSwitch = (pageNumber: number): void => {
    setSkippedIndicators((pageNumber - 1) * pageSize);
  };

  /**
   * Fonction de rappel lorsqu'une personne souhaite supprimer un indicateur.
   *
   * @param {StandardIndicator} indicator L'indicateur que l'on souhaite supprimer.
   */
  const onDeleteIndicator = (indicator: StandardIndicator): void => {
    setIndicatorToDelete(indicator);
    setConfirmModalShow(true);
  };

  /**
   * Fonction de rappel lorsqu'une personne annule la suppression d'un indicateur.
   */
  const onClose = (): void => {
    setConfirmModalShow(false);
    setIndicatorToDelete(undefined);
  };

  /**
   * Fonction de rappel lorsqu'une personne confirme la suppression d'un indicateur.
   */
  const onConfirm = (): void => {
    setConfirmModalShow(false);
    deleteIndicator({
      variables: {
        id: indicatorToDelete?.id ?? '',
      },
      onError: () => {},
    }).then(result => {
      if (
        (result.data?.deleteIndicator.errors ?? []).length === 0 &&
        (result.data?.deleteIndicator.indicator?.id ?? '') !== ''
      ) {
        setSuccessDeleteMessage("L'indicateur a été supprimé avec succès");
        setTimeout(() => {
          setSuccessDeleteMessage('');
        }, 3000);
      }
    });
  };

  /**
   * Fonction lorsqu'une personne écrit le nom d'un attribut.
   */
  const onAttributeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setAttributeName(event.target.value);
  };

  /**
   * Fonction de rappel lorsqu'un attribut est sélectionné.
   *
   * @param key La clé de l'attribut séléctionnée. Cette clé provient du `Dropdown.Item`.
   * @param e L'événement. La propriété n'est pas utilisée.
   */
  const onAttributeSelect = (
    key: string | null,
    e: React.SyntheticEvent<unknown>
  ): void => {
    if (key !== null) {
      // There's something to do.
      const attribute = attributeData?.attributes?.items?.find(
        a => a.id === key
      );
      if (attribute !== undefined) {
        setFilters([
          ...filters,
          {
            attribute,
            operation: attribute.acceptedIndicatorFilterOperations[0],
            negate: false,
            value: '',
          },
        ]);
        setFilterShow(false);
        setCurrentFilter(attribute.id);
        setFilterBeenModified(true);
      }
    }
  };

  /**
   * Fonction de rappel lorsqu'un filtre est activé par un clic.
   *
   * @param filter Le filtre cliqué.
   */
  const onFilterToggle = (filter: Filter): void => {
    if (filter.attribute.id === currentFilter) {
      setCurrentFilter('');
    } else {
      setCurrentFilter(filter.attribute.id);
    }
  };

  /**
   * Fonction de rappel lorsqu'un(e) utilisateur(rice) sélectionne une opération de filtre.
   * @param key La clé de l'opération sélectionnée. Cette clé provient de `<Dropdown.Item>`.
   * @param index L'indice où est situé le filtre dans l'état du composant.
   * @param filter Le filtre modifié.
   * @param event L'événement de changement de React.
   */
  const onFilterOperationSelect = (
    key: string | null,
    index: number,
    filter: Filter,
    event: React.SyntheticEvent<unknown>
  ): void => {
    if (key !== null) {
      filter.negate = key.includes('negate');
      const operation = key.split('-')[0];
      const operationValue =
        filter.attribute.acceptedIndicatorFilterOperations.find(
          f => f.enumValue === operation
        );
      if (operationValue !== undefined) {
        filter.operation = operationValue;
        const newFilters = [
          ...filters.slice(0, index),
          filter,
          ...filters.slice(index + 1),
        ];
        setFilters(newFilters);
        setFilterBeenModified(true);
      }
    }
  };

  /**
   * Fonction de rappel pour un changement de valeur de filtre.
   * @param filter Le filtre modifié
   * @param index L'indice où est situé le filtre dans l'état du composant.
   * @param event L'événement de changement de React.
   */
  const onFilterChange = (
    filter: Filter,
    index: number,
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    filter.value = event.target.value;
    const newFilters = [
      ...filters.slice(0, index),
      filter,
      ...filters.slice(index + 1),
    ];
    setFilters(newFilters);
    setFilterBeenModified(true);
  };

  /**
   * Fonction de rappel lorsqu'une touche est pressée sur un filtre.
   *
   * Cette fonction existe puisque l'événement `change` des filtres
   * ne semble pas capturer la touche _espace_. Cet événement s'assure
   * que la touche espace est pris en compte lors de la saisie d'une
   * valeur pour le filtre.
   *
   * @param filter Le filtre concernée par l'événement
   * @param index L'indice où est situé le filtre dans l'état du composant.
   * @param event L'événement de saisie de React.
   */
  const onFilterKeyDown = (
    filter: Filter,
    index: number,
    event: React.KeyboardEvent<HTMLInputElement>
  ): void => {
    if (event.key === ' ') {
      filter.value = `${filter.value} `;
      const newFilters = [
        ...filters.slice(0, index),
        filter,
        ...filters.slice(index + 1),
      ];
      setFilters(newFilters);
      setFilterBeenModified(true);
    }
  };

  /**
   * Foncition de rappel pour la suppression d'un filtre.
   *
   * @param index L'indice où est situé le filtre.
   */
  const onDeleteFilter = (index: number): void => {
    const newFilters = [
      ...filters.slice(0, index),
      ...filters.slice(index + 1),
    ];
    setFilters(newFilters);
    setFilterBeenModified(true);
  };

  /**
   * Fonction de rappel pour l'application des filtres.
   */
  const onFilterApply = (): void => {
    if (filters.length === 0) {
      setSearch(undefined);
    } else {
      setSearch({
        filters: filters.map(f => ({
          attributeId: f.attribute.id,
          negate: f.negate,
          operation: f.operation.enumValue,
          value: f.value,
        })),
      });
    }
    setFilterBeenModified(false);
  };

  // <- Fonctions de rendu du contenu. ->

  const processDisposition = (
    mandatoryAttributes: string[]
  ): Array<(item: StandardIndicator, index: number) => React.ReactElement> => {
    const renderFunctions = mandatoryAttributes.map<
      (item: StandardIndicator, index: number) => React.ReactElement
    >(
      e =>
        function Mapper(item, i) {
          const temp = item.attributes.find(x => x.attribute.id === e);
          const value = temp?.primaryValue ?? temp?.referenceValue?.value ?? '';
          return <div key={i}>{value}</div>;
        }
    );

    renderFunctions.push((e, i) => (
      <>
        <Stack direction="horizontal" gap={2}>
          <SimpleTooltip text="Consulter l'indicateur">
            <Button variant="primary" href={`/indicateurs/${e.id}`}>
              <FileEarmarkFill />
            </Button>
          </SimpleTooltip>
          {e.canBeEdited && (
            <>
              <SimpleTooltip text="Modifier l'indicateur">
                <Button href={`/indicateurs/${e.id}/modification`}>
                  <PencilFill />
                </Button>
              </SimpleTooltip>
              <SimpleTooltip text="Supprimer l'indicateur">
                <ButtonLoading
                  variant="danger"
                  onClick={() => {
                    onDeleteIndicator(e);
                  }}
                  loading={e.id === indicatorToDelete?.id && loading}
                  testid={`delete-indicator-${e.id}`}
                >
                  <Trash3Fill />
                </ButtonLoading>
              </SimpleTooltip>
            </>
          )}
        </Stack>
      </>
    ));

    return renderFunctions;
  };

  const displayList = (
    data: ListIndicatorsWithMandatoryAttributesQuery | undefined
  ): React.ReactElement => {
    const mandatoryAttributes = data?.attributes?.items?.map(a => a.id) ?? [];
    const titles = data?.attributes?.items?.map(a => a.name) ?? [];
    titles.push('Actions');
    const indicators: StandardIndicator[] = data?.indicators?.items ?? [];
    return (
      <SimpleActionTable<StandardIndicator>
        titles={titles}
        disposition={processDisposition(mandatoryAttributes)}
        data={indicators}
        key={indicators.length}
        pageInfo={{
          skipped: skippedIndicators,
          took: pageSize,
          total: data?.indicators?.totalCount ?? 0,
        }}
        onPageSwitch={onPageSwitch}
        onPageSizeSwitch={onPageSizeChange}
      />
    );
  };

  /**
   * Rendu des attributs pour les filtres.
   *
   * @returns L'élément rendu.
   */
  const renderAttributes = (): React.ReactElement => (
    <>
      {attributeData?.attributes?.items?.map(a => (
        <Dropdown.Item key={a.id} eventKey={a.id}>
          {a.name}
        </Dropdown.Item>
      ))}
    </>
  );

  /**
   * Rendu des opérations possibles pour chaque type.
   *
   * @returns L'élément rendu.
   */
  const renderFilterOperations = (
    attribute: AttributeForFilter
  ): React.ReactElement => (
    <>
      {attribute.acceptedIndicatorFilterOperations.map(o => (
        <Fragment key={`${attribute.id}-${o.enumValue}`}>
          <Dropdown.Item eventKey={`${o.enumValue}`}>
            {o.readableValue}
          </Dropdown.Item>
          <Dropdown.Item eventKey={`${o.enumValue}-negate`}>
            {o.negatedReadableValue}
          </Dropdown.Item>
        </Fragment>
      ))}
    </>
  );

  /**
   * Rendu des filtres sélectionnées.
   *
   * @returns L'élément rendu.
   */
  const renderFilters = (): React.ReactElement => (
    <>
      {filters.map((f, i) => (
        <Col md={2} key={`filter-${f.attribute.id}`}>
          <Dropdown
            autoClose="outside"
            show={currentFilter === f.attribute.id}
            onToggle={() => {
              onFilterToggle(f);
            }}
          >
            <Dropdown.Toggle
              size="sm"
              variant={
                f.operation.enumValue !==
                  IndicatorFilterOperationOption.IsDefined && f.value === ''
                  ? 'light'
                  : 'info'
              }
            >
              {f.attribute.name}
            </Dropdown.Toggle>
            <Dropdown.Menu className="filter-dropdown">
              <Dropdown.Item className="no-highlight" as="div">
                <SimpleTooltip text="supprimer le filtre">
                  <Button
                    size="sm"
                    variant="danger"
                    className="ms-auto"
                    data-testid={`delete-${f.attribute.id}`}
                    onClick={() => {
                      onDeleteFilter(i);
                    }}
                  >
                    <Trash3Fill />
                  </Button>
                </SimpleTooltip>
              </Dropdown.Item>
              <Dropdown.Item className="no-highlight" as="div">
                <Dropdown
                  onSelect={(key, event) => {
                    onFilterOperationSelect(key, i, f, event);
                  }}
                >
                  <Dropdown.Toggle variant="light">
                    {f.negate
                      ? f.operation.negatedReadableValue
                      : f.operation.readableValue}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {renderFilterOperations(f.attribute)}
                  </Dropdown.Menu>
                </Dropdown>
              </Dropdown.Item>
              {f.operation.enumValue !== 'IS_DEFINED' && (
                <Dropdown.Item className="no-highlight">
                  <FloatingTextInput
                    id={`attribute-${f.attribute.id}`}
                    testId={`test-attribute-${f.attribute.id}`}
                    label="Contenu du filtre..."
                    value={f.value}
                    onChange={event => {
                      onFilterChange(f, i, event);
                    }}
                    onKeyDown={event => {
                      onFilterKeyDown(f, i, event);
                    }}
                    required={false}
                  />
                </Dropdown.Item>
              )}
            </Dropdown.Menu>
          </Dropdown>
        </Col>
      ))}
    </>
  );

  return (
    <>
      <Row className="justify-content-md-center mb-2">
        <Col md={10}>
          <Row className="justify-content-md-start">
            <Col md={9}>
              <div className="h2">Liste des indicateurs</div>
            </Col>
            <Col md={3}>
              <div className="d-grid">
                <Button variant="success" href="/indicateurs/ajout">
                  Nouveau
                </Button>
              </div>
            </Col>
          </Row>
          {attributeError !== undefined && (
            <Alert variant="danger">{attributeError.message}</Alert>
          )}
          <Row className="mb-2">
            <Col md={2}>
              <Button
                size="sm"
                active={filterActive}
                onClick={() => {
                  setFilterActive(!filterActive);
                }}
              >
                Filtrer
              </Button>
            </Col>
          </Row>
          {filterActive && (
            <Row className="mb-2">
              {renderFilters()}
              <Col md={2}>
                <Dropdown
                  autoClose="outside"
                  onSelect={onAttributeSelect}
                  show={filterShow}
                  onToggle={() => {
                    setFilterShow(!filterShow);
                  }}
                >
                  <Dropdown.Toggle size="sm" variant="secondary">
                    Ajouter un filtre
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item as="div" className="no-highlight">
                      <FloatingTextInput
                        id="attribute-to-filter"
                        testId="attribute-to-filter"
                        label="Filtrer par..."
                        value={attributeName}
                        onChange={onAttributeChange}
                        required={false}
                      />
                    </Dropdown.Item>
                    {attributesLoading && (
                      <Dropdown.Item as="div" className="no-highlight">
                        <span className="spinner-border spinner-border-sm me-2" />
                      </Dropdown.Item>
                    )}
                    {!attributesLoading && renderAttributes()}
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
              {hasFilterBeenModified && (
                <Col md={2}>
                  <Button size="sm" onClick={onFilterApply}>
                    Appliquer
                  </Button>
                </Col>
              )}
            </Row>
          )}
          <Row>
            <ContentLoader
              data={displayList}
              query={LIST_INDICATORS_WITH_MANDATORY_ATTRIBUTE}
              variables={{ skip: skippedIndicators, take: pageSize, search }}
            />
          </Row>
        </Col>
      </Row>
      <SimpleActionModal
        successMessage={successDeleteMessage}
        errorMessages={deleteErrors}
        genericErrorMessage="Des erreurs sont survenues lors de la suppression de l'indicateur"
        show={confirmModalShow}
        onClose={onClose}
        onConfirm={onConfirm}
        title="Confirmer la suppression"
        confirmButtonText="Supprimer l'indicateur"
      >
        <>
          <p>Êtes-vous certain(e) de vouloir supprimer l&apos;indicateur?</p>
          <p>L&apos;action est irréversible.</p>
        </>
      </SimpleActionModal>
    </>
  );
}
