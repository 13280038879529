import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import { useQuery, useMutation, useApolloClient } from '@apollo/client';
import { Outlet, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

import { ME } from '../../graphql/queries';
import { LOGOUT } from '../../graphql/mutations';
import { Button, Dropdown } from 'react-bootstrap';

/**
 * Interface par défaut de l'application. Elle doit être rendue
 * par le routeur, qui s'occupe ensuite d'afficher le contenu de
 * la vue sous la barre de navigation.
 *
 * @param {LayoutProps} props Les propriétés du composant.
 * Ils se résument essentiellement aux composants enfant.
 * @see {@link LayoutProps}
 *
 * @returns Le composant rendu.
 */
export function Layout(): React.ReactElement {
  const onLogout = (event: React.MouseEvent): void => {
    event.preventDefault();
    logout().then(() => {
      Cookies.remove('loggedIn');
      client.resetStore();
      navigate('/');
    });
  };

  const isLoggedIn = Cookies.get('loggedIn') !== undefined;

  const { data } = useQuery(ME, {
    errorPolicy: 'ignore',
    skip: !isLoggedIn,
  });
  const [logout] = useMutation(LOGOUT);
  const client = useApolloClient();

  const navigate = useNavigate();

  const adminRender =
    data?.user?.roles.includes('Administrator') ?? false ? (
      <>
        <Nav.Link href="/administration">Administration</Nav.Link>
      </>
    ) : (
      <></>
    );

  const attributeRender =
    (data?.user?.roles.includes('Researcher') ?? false) ||
    (data?.user?.roles.includes('Administrator') ?? false) ? (
      <>
        <Nav.Link href="/attributs">Attributs</Nav.Link>
      </>
    ) : (
      <></>
    );

  const indicatorRender =
    (data?.user?.roles.includes('Researcher') ?? false) ||
    (data?.user?.roles.includes('Administrator') ?? false) ? (
      <>
        <Nav.Link href="/indicateurs">Indicateurs</Nav.Link>
      </>
    ) : (
      <></>
    );

  const userRender =
    data?.user === undefined || data?.user === null ? (
      <Button variant="outline-light" href="/connexion">
        Connexion
      </Button>
    ) : data?.user?.firstName !== undefined &&
      data?.user?.firstName !== null &&
      data?.user?.lastName !== undefined &&
      data?.user?.lastName !== null ? (
      <Dropdown>
        <Dropdown.Toggle variant="outline-light">
          {data.user.firstName} {data.user.lastName}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item onClick={onLogout}>Se déconnecter</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    ) : (
      <Button variant='outline-light' onClick={onLogout}>Se déconnecter</Button>
    );

  return (
    <>
      <Navbar bg="dark" expand="lg" variant="dark" className="mb-2">
        <Container>
          <Navbar.Brand href="/">BCIÉC</Navbar.Brand>
          <Navbar.Toggle aria-controls="app-navbar" />
          <Navbar.Collapse id="app-navbar">
            <Nav className="me-auto">
              {adminRender}
              {indicatorRender}
              {attributeRender}
            </Nav>
            <Nav className="ms-auto">{userRender}</Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Container className="mb-2">
        <Outlet />
      </Container>
    </>
  );
}
