import React, { useRef, useState } from 'react';
import ContentLoader from '../ContentLoader';
import { LIST_MANDATORY_AND_NOT_MANDATORY_ATTRIBUTES } from '../../graphql/queries';
import type { SimpleAttribute } from '../../graphql/types';
import type {
  IndicatorAttributeInput,
  ListMandatoryAndNotMandatoryAttributesQuery,
} from '../../__generated__/graphql';
import { IndicatorAttribute as IAFormElement } from '../IndicatorAttribute';
import { Col, Dropdown, Row, Form } from 'react-bootstrap';
import { ButtonLoading } from '../ButtonLoading';
import SavingStatus from '../SavingStatus';

/**
 * Les propriétés nécessaire à l'affichage du formulaire
 */
export interface IndicatorFormProps {
  /**
   * La liste des valeurs d'attributs desquels le formulaire doit démarrer.
   */
  indicatorAttributes?: IndicatorAttributeInput[];

  /**
   * Fonction de rappel lorsque le formulaire est envoyé.
   * @param ias Les valeurs des attributs pour l'indicateur lors de
   * l'envoie du formulaire
   */
  onFormSubmit: (ias: IndicatorAttributeInput[]) => void;

  /**
   * Indique si l'action de soumission du formulaire est complétée
   */
  isComplete: boolean;

  /**
   * Les erreurs qui sont survenues à l'envoie du formulaire
   */
  errors: string[];

  /**
   * Indique si l'envoit du formulaire est en cours de chargement
   */
  loading: boolean;
}

/**
 * Ce composant permet l'affichage d'un formulaire permettan d'effectuer
 * des modifications sur des valeurs d'attribut données.
 * @param props Les propriétés nécessaire à l'affichage du composant
 * @returns Le composant rendu par React
 */
export function IndicatorForm(props: IndicatorFormProps): React.ReactElement {
  // La façon dont l'information chargée est affichée
  const displayAttributes = (
    data: ListMandatoryAndNotMandatoryAttributesQuery | undefined
  ): React.ReactElement => {
    const mandatory =
      data?.mandatory?.items?.map<SimpleAttribute>(value => ({
        attributeId: value.id,
        name: value.name,
      })) ?? [];
    const optional =
      data?.optional?.items?.map<SimpleAttribute>(value => ({
        attributeId: value.id,
        name: value.name,
      })) ?? [];

    return (
      <IndicatorFormDisplay
        onFormSubmit={props.onFormSubmit}
        indicatorAttributes={props.indicatorAttributes}
        mandatoryAttributes={mandatory}
        optionalAttributes={optional}
        isComplete={props.isComplete}
        errors={props.errors}
        loading={props.loading}
      />
    );
  };

  return (
    <>
      <ContentLoader
        query={LIST_MANDATORY_AND_NOT_MANDATORY_ATTRIBUTES}
        data={displayAttributes}
      />
    </>
  );
}

/**
 * Les propriétés nécessaires à l'affichage des données contenues
 * dans le formulaire
 */
interface IndicatorFormDisplayProps extends IndicatorFormProps {
  /**
   * Les attributs devant obligatoirement être inclus dans le formulaire
   */
  mandatoryAttributes: SimpleAttribute[];

  /**
   * Les attributs pouvant être ajoutés dans le formulaire
   */
  optionalAttributes: SimpleAttribute[];
}

/**
 * Ce composant permet d'afficher le contenu du formulaire
 * @param props Les propriétés nécessaires à l'affichage du composant
 * @returns Le composant rendu par React
 */
function IndicatorFormDisplay(
  props: IndicatorFormDisplayProps
): React.ReactElement {
  const [updateKey, setUpdateKey] = useState(0);
  const [validated, setValidated] = useState(false);
  const formRef = useRef<HTMLFormElement>(null);

  // On crée la liste des attributs qui doivent être inclus au chargement du formulaire
  const baseTrackedAttributes =
    props.mandatoryAttributes.map<IndicatorAttributeInput>(a => ({
      attributeId: a.attributeId,
      value: '',
    }));
  
  // On ajoute les attributs de l'indicateur
  props.indicatorAttributes?.forEach(a => {
    const inList = baseTrackedAttributes.find(v => v.attributeId === a.attributeId);
    if (inList === undefined)
      baseTrackedAttributes.push(a);
    else
      inList.value = a.value;
  });

  // Les attributs qui sont suivis par le formulaire
  const [trackedAttributes, setTrackedAttributes] = useState<
    IndicatorAttributeInput[]
  >(baseTrackedAttributes);

  // Les attributs optionnels au formulaire
  const optionalAttributes = (): SimpleAttribute[] => {
    const result: SimpleAttribute[] = [];
    props.optionalAttributes.forEach(value => {
      if (
        trackedAttributes.findIndex(x => x.attributeId === value.attributeId) <
        0
      )
        result.push(value);
    });
    return result;
  };

  // Les différentes fonctions de rappel pour le formulaire

  const onTrackedAttributeDelete = (id: string): void => {
    const i = trackedAttributes.findIndex(value => value.attributeId === id);
    trackedAttributes.splice(i, 1);
    setTrackedAttributes([...trackedAttributes]);
  };

  const onTrackedAttributeValueChange = (id: string, value: string): void => {
    const i = trackedAttributes.find(value => value.attributeId === id);
    if (i !== undefined) i.value = value;
    setTrackedAttributes([...trackedAttributes]);
  };

  const addTrackedAttribute = (id: string): void => {
    trackedAttributes.push({ attributeId: id, value: '' });
    setTrackedAttributes([...trackedAttributes]);
    setValidated(false);
  };

  const onFormSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    if (formRef.current?.checkValidity() ?? false)
    {
      setUpdateKey(x => x + 1);
      props.onFormSubmit(trackedAttributes);
    }
    setValidated(true);
  };

  // Les fonctions responsables de l'affichage des composants

  const displayTrackedAttributes = (): React.ReactElement => {
    return (
      <>
        {trackedAttributes.map<React.ReactElement>(a => (
          <IAFormElement
            key={a.attributeId}
            id={a.attributeId}
            value={a.value ?? ''}
            onDelete={onTrackedAttributeDelete}
            onValueChange={onTrackedAttributeValueChange}
          />
        ))}
      </>
    );
  };

  const optionalDropdownItems = (): React.ReactElement => {
    return (
      <>
        {optionalAttributes().map<React.ReactElement>(e => (
          <Dropdown.Item
            key={e.attributeId}
            onClick={() => {
              addTrackedAttribute(e.attributeId);
            }}
          >
            {e.name}
          </Dropdown.Item>
        ))}
      </>
    );
  };

  return (
    <>
      <Form onSubmit={onFormSubmit} validated={validated} ref={formRef} noValidate>
        {displayTrackedAttributes()}
        <Dropdown className="mb-3">
          <Dropdown.Toggle variant="primary" id="dropdown-attribute">
            Ajouter un attribut
          </Dropdown.Toggle>

          <Dropdown.Menu>{optionalDropdownItems()}</Dropdown.Menu>
        </Dropdown>
        <Row>
          <Col md="8">
            <SavingStatus
              complete={props.isComplete}
              errors={props.errors}
              loading={props.loading}
              key={updateKey}
            />
          </Col>
          <Col md="4">
            <div className="d-grid gap-2">
              <ButtonLoading type="submit" variant="success" loading={false}>
                Enregistrer
              </ButtonLoading>
            </div>
          </Col>
        </Row>
      </Form>
    </>
  );
}
