import React from 'react';
import { Alert, Button, Col, ListGroup, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { QuestionCircle } from 'react-bootstrap-icons';
import ContentLoader from '../../Components/ContentLoader';
import { READ_INDICATOR } from '../../graphql/queries';
import type { ReadIndicatorQuery } from '../../__generated__/graphql';
import type { ExtendedIndicatorAttribute } from '../../graphql/types';
import SimpleTooltip from '../../Components/SimpleTooltip';
import { ReturnLayout } from '../../Components/ReturnLayout';

/**
 * Ce composant permet de consulter les détails d'un indicateur. Il
 * est directement accessible à partir de l'URL `/indicateurs/:id`
 * @returns Le composant rendu par React
 */
export function ReadIndicator(): React.ReactElement {
  // L'identifiant unique passé dans l'URL de la page
  const { id } = useParams();

  // Les paramètres de formattage des titres
  const subtitleFormat = 'text-right mb-1 ms-2 fw-bold h4';

  // La fonction de rappel qui place le contenu
  const displayContent = (
    data: ReadIndicatorQuery | undefined
  ): React.ReactElement => {
    const indicator = data?.indicators?.items?.at(0);
    if (indicator === undefined) {
      return (
        <Alert variant="danger">
          L&apos;identifiant d&apos;indicateur n&apos;est lié à aucun indicateur
          connu.
        </Alert>
      );
    }

    return (
      <>
        <Row className="my-3">
          <Col>
            <div className="h1">Détail d&apos;un indicateur</div>
          </Col>
        </Row>
        {/* Attributs obligatoires */}
        <Row>
          <Col>
            <div>
              <p className={subtitleFormat}>Attributs principaux</p>
            </div>
          </Col>
        </Row>
        <Row className="mb-4">
          <Col>
            {renderIndicatorAttributes(
              indicator.attributes
                .filter(e => e.attribute.mandatory)
                .filter((e, i) => i % 2 === 0)
            )}
          </Col>
          <Col>
            {renderIndicatorAttributes(
              indicator.attributes
                .filter(e => e.attribute.mandatory)
                .filter((e, i) => i % 2 === 1)
            )}
          </Col>
        </Row>
        {/* Attributs optionnels */}
        {indicator.attributes.filter(e => !e.attribute.mandatory).length >
          0 && (
          <div>
            <p className={subtitleFormat}>Attributs additionnels</p>
          </div>
        )}
        <Row className="mb-1">
          <Col>
            {renderIndicatorAttributes(
              indicator.attributes
                .filter(e => !e.attribute.mandatory)
                .filter((e, i) => i % 2 === 0)
            )}
          </Col>
          <Col>
            {renderIndicatorAttributes(
              indicator.attributes
                .filter(e => !e.attribute.mandatory)
                .filter((e, i) => i % 2 === 1)
            )}
          </Col>
        </Row>
        {/* Métadonnées */}
        <Row>
          <Col>
            <hr />
          </Col>
        </Row>
        <Row>
          <Col>
            <div>
              <p className={subtitleFormat}>Métadonnées</p>
            </div>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md="auto">
            <ListGroup>
              <ListGroup.Item>
                Créé le : {new Date(indicator.createdOn).toLocaleString()}
              </ListGroup.Item>
              <ListGroup.Item>
                Modifié le : {new Date(indicator.editedOn).toLocaleString()}
              </ListGroup.Item>
              <ListGroup.Item>
                Créateur : {indicator.owner.firstName}{' '}
                {indicator.owner.lastName}
              </ListGroup.Item>
            </ListGroup>
          </Col>
        </Row>
        {/* Exemples */}
        <Row>
          <Col>
            <hr />
          </Col>
        </Row>
        <Row>
          <Col md={9}>
            <p className={subtitleFormat}>Exemples</p>
          </Col>
          <Col md={3}>
            <div className="d-grid">
              <Button
                variant="success"
                href={`/indicateurs/${id}/exemples/ajout`}
              >
                Nouveau
              </Button>
            </div>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md="auto">
            <Alert variant="info">
              Les exemples arrivent bientôt pour les indicateurs. Les exemples
              sont un moyen d&apos;illustrer où est utilisé cet indicateur. Pour
              le moment, vous pouvez tester l&apos;ajout d&apos;un exemple.
            </Alert>
          </Col>
        </Row>
      </>
    );
  };

  const renderIndicatorAttributes = (
    attributes: ExtendedIndicatorAttribute[]
  ): React.ReactElement => (
    <ListGroup>
      {attributes.map<React.ReactElement>((e, i) =>
        renderIndicatorAttribute(e)
      )}
    </ListGroup>
  );

  // Fait le rendu des indicateurs individuellement
  const renderIndicatorAttribute = (
    a: ExtendedIndicatorAttribute
  ): React.ReactElement => {
    return (
      <ListGroup.Item>
        <div className="d-flex align-items-center">
          <span>
            <a
              className="text-decoration-none link-dark h5"
              href={`/attributs/${a.attribute.id}`}
            >
              {a.attribute.name}
            </a>
          </span>
          <span className="mx-2">
            <SimpleTooltip text={a.attribute.description}>
              <QuestionCircle size={15} />
            </SimpleTooltip>
          </span>
          <small className="fw-light text-mutedf fs-6">
            ({a.attribute.readableValueType})
          </small>
        </div>
        <div>
          <span>{a.primaryValue ?? a.referenceValue?.value}</span>
        </div>
      </ListGroup.Item>
    );
  };

  return (
    <ReturnLayout href="/indicateurs">
      <Row>
        <Col>
          <ContentLoader
            query={READ_INDICATOR}
            variables={{ indicatorId: id ?? '' }}
            data={displayContent}
          />
        </Col>
      </Row>
    </ReturnLayout>
  );
}
