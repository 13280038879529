import React from "react";
import { Col, Row } from "react-bootstrap";
import IconTextButton from "../IconTextButton";
import { ArrowBarLeft } from "react-bootstrap-icons";

/**
 * Les propriétés nécessaires au fonctionnement et à l'affichage
 * du composant
 */
export interface ReturnLayoutProps {
    /**
     * Le lien à suivre sur le bouton de retour
     */
    href: string;

    /**
     * Contenu à afficher sur la page
     */
    children?: React.ReactNode;
}

/**
 * Ce composant permet la standardisation de l'affichage d'un bouton
 * de retour.
 * @param props Les propriétés nécessaire à l'affichage du composant
 * @returns Le composant rendu par React
 */
export function ReturnLayout(props: ReturnLayoutProps): React.ReactElement {
    return (
        <Row className="justify-content-md-center">
          <Col>
            <IconTextButton text='Retour' href={props.href} variant='primary'>
              <ArrowBarLeft />
            </IconTextButton>
          </Col>
          <Col md="10">
            {props.children}
          </Col>
        </Row>
      );
}