import { gql } from '../__generated__/gql';

/**
 * Mutation GraphQL utilisée pour l'authentification.
 *
 * Il est important d'utiliser la commande `npm run compile`
 * une fois la commande écrite pour rafraichier les types TypeScript
 * chargés dynamiquement.
 */
export const LOGIN = gql(`
mutation Login($email: String!, $password: String!) {
  login(input: { email: $email, password: $password}) {
    user {
      id
      email
      roles
      firstName
      lastName
    }
    errors {
      ... on InvalidLoginError {
        message
      }
    }
  } 
}
`);

/**
 * Mutation GraphQL utilisée pour se déconnecter.
 *
 * Il est important d'utiliser la commande `npm run compile`
 * une fois la commande écrite pour rafraichir les types TypeScript
 * chargés dynamiquement.
 */
export const LOGOUT = gql(`
mutation Logout {
  logout {
    boolean
  }
}
`);

/**
 * Mutation GraphQL utilisée pour archiver un compte.
 *
 * Il est important d'utiliser la commande `npm run compile`
 * une fis la commande écrite pour rafraichir les types TypeScript
 * chargés dynamiquement.
 */
export const ARCHIVE_USER = gql(`
mutation ArchiveUser($userId: String!) {
  archiveUser(input: {userId: $userId}) {
    user {
      id
      state
    }
    errors {
      ... on NotFoundError {
        message
      }
      ... on GenericError {
        message
      }
    }
  }
}
`);

/**
 * Mutation GraphQL permettant de restaurer le compte d'un utilisateur archivé.
 */
export const RESTORE_USER = gql(`
mutation RestoreUser($userId: String!) {
  restoreUser(input: {userId: $userId}) {
    user {
      id
      state
    }
    errors {
      ... on Error {
        message
      }
    }
  }
}
`);

/**
 * Mutation GraphQL pour ajouter un utilisateur
 */
export const ADD_USER = gql(`
mutation AddUser($firstName: String!, $lastName: String!, $email: String!, $password: String!) {
  addUser(input: { firstName: $firstName, lastName: $lastName, email: $email, password: $password}) {
    user {
      id
      email
      roles
      firstName
      lastName
    }
    errors {
      ... on Error {
        message
      }
    }
  } 
}
`);

/**
 * Mutation GraphQL utilisée pour ajouter un attribut au système
 */
export const ADD_ATTRIBUTE = gql(`
mutation AddAttribute ($name: String!, $description: String!, $valueType: AttributeValueOption!, $mandatory: Boolean!, $unique: Boolean!,$values: [String!] ){
  addAttribute (input: {
    name:$name,  
    description:$description
    valueType: $valueType,
    mandatory: $mandatory,
    unique: $unique,
    values: $values
  }
  ) {
    attribute {
      id
      name
      mandatory
      unique
      valueType
      readableValueType
      values {
        id
        value
      }
    }
    errors {
      ... on Error {
        message
      }
    }
  }
}
`);

/**
 * Mutation GraphQL utilisée pour supprimer un attribut d'indicateur
 */
export const DELETE_ATTRIBUTE = gql(`
mutation DeleteAttribute ($attributeId: String!) {
  deleteAttribute (input : { id : $attributeId}){
    attribute {
      id
      name
    }
    errors {
      ... on Error {
        message
      }
    }
  }
}
`);

/**
 * Mutation GraphQL utilisée pour modifier un attribut
 */
export const EDIT_ATTRIBUTE = gql(`
mutation EditAttribute ($id:String!, $name:String, $description:String, $mandatory:Boolean, $unique:Boolean, $valueType: AttributeValueOption, $values:[AttributeValueInput!]){
  editAttribute (input: {
    id: $id, 
    name: $name,
    description: $description,
    mandatory: $mandatory,
    unique: $unique,
    valueType : $valueType,
    values: $values
    }) 
  {
    attribute {
      id
      name
      description
      mandatory
      unique
      valueType
      readableValueType
      canBeEdited
      canBeDeleted
      editedOn
      values {
        id
        value
        canBeDeleted
      }
    }
    errors {
      ... on Error {
        message
      }
    }
  }
}
`);

/**
 * Mutation GraphQL pour ajouter un indicateur à l'application
 */
export const ADD_INDICATOR = gql(`
mutation AddIndicator ($attributes: [IndicatorAttributeInput!]!) {
  addIndicator (input: {attributes: $attributes})
    {
    indicator {
      id
    }
    errors {
      ... on Error {
        message
      }
    }
  }
}
`);

/**
 * Mutation GraphQL utilisée pour supprimer un indicateur.
 */
export const DELETE_INDICATOR = gql(`
mutation DeleteIndicator ($id: String!) {
  deleteIndicator(input: {id: $id}) {
    indicator {
      id
    }
    errors {
      ... on Error {
        message
      }
    }
  }
}
`);

/*
 * Mutation GraphQL utilisée pour modifier un utilisateur
 */
export const EDIT_USER = gql(`
mutation EditUser ($id:String!, $firstName:String, $lastName:String, $email:String, $password:String, $passwordConfirm:String, $roles:[String!]){
  editUser (input: {
    userId: $id
    firstName: $firstName
    lastName: $lastName
    email: $email
    password: $password
    passwordConfirm: $passwordConfirm
    roles: $roles
  })
  {
    user {
      id
      firstName
      lastName
      email
      roles
    }
    errors {
      ... on Error {
        message
      }
    }
  }
}
`);

/**
 * Mutation GraphQL utilisée pour modifier les valeurs d'attribut d'un indicateur
 */
export const EDIT_INDICATOR = gql(`
mutation EditIndicator ($id:String!, $attributes: [IndicatorAttributeEditInput!]!) {
  editIndicator (input: {
    id: $id
    attributes: $attributes
  }
  ) {
    indicator {
      id
      attributes {
        id
      }
    }
    errors {
      ... on Error {
        message
      }
    }
  }
}
`);

/**
 * Mutation GraphQL pour enregistrer un document de type `Fichier` associé à un indicateur.
 */
export const ADD_FILE_DOCUMENT = gql(`
mutation AddFileDocument ($fileName: String!, $title: String!, $abstract: String!, $authors: [String!], $publicationYear: Int) {
  addFileDocument(input: {
    fileName: $fileName,
    title: $title,
    abstract: $abstract,
    authors: $authors,
    publicationYear: $publicationYear
  }) {
    uploadToken {
      token
      uploadURL
    }
    errors {
      ... on Error {
        message
      }
    }
  }
}
`);

/**
 * Mutation GraphQL pour enregistrer un document de type `Lien` associé à un indicateur.
 */
export const ADD_LINK_DOCUMENT = gql(`
mutation AddLinkDocument ($url: String!, $title: String!, $abstract: String!, $authors: [String!], $publicationYear: Int) {
  addLinkDocument(input: {
    url: $url,
    title: $title,
    abstract: $abstract,
    authors: $authors,
    publicationYear: $publicationYear
  }) {
    document {
      id
    }
    errors {
      ... on Error {
        message
      }
    }
  }
}
`);

/**
 * Mutation GraphQL pour enregistrer un exemple associé à un indicateur.
 */
export const ADD_EXAMPLE = gql(`
mutation AddExample ($indicatorId: String!, $documentId: String!, $remark: String!) {
  addExample(input: {
    indicatorId: $indicatorId,
    documentId: $documentId,
    remark: $remark
  }) {
    example {
      id
    }
    errors {
      ... on Error {
        message
      }
    }
  }
}
`);
