import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { RouterProvider } from 'react-router-dom';
import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  createHttpLink,
} from '@apollo/client';

import router from './routes';

import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const apiUrl =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:5268/graphql/http'
    : 'https://fenris2.ctn.etsmtl.ca/graphql/http';

/**
 * Le client Apollo permet d'effectuer des requêtes GraphQL
 */
const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: createHttpLink({
    uri: apiUrl,
    credentials: 'include',
  }),
});

/**
 * C'est ici que le projet est démarré en fournissant le fournisseur Apollo
 * ainsi que le routeur au composant racine de l'application
 */
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <RouterProvider router={router} />
    </ApolloProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// eslint-disable-next-line @typescript-eslint/no-floating-promises
reportWebVitals();
