import React from 'react';
import { Form } from 'react-bootstrap';

/**
 * Les propriétés nécessaire au rendu du composant
 */
export interface ElementSelectorProps {
  /**
   * L'identifiant utilisé pour tester le composant
   */
  testId?: string;

  /**
   * Les éléments à inclure dans le sélecteur. Ce sont les éléments affichés
   * à l'utilisateur.
   */
  elements: string[];

  /**
   * Les valeurs associées à chaque élément
   *
   * Valeur par défaut: Valeurs de la propriété `elements`
   */
  values?: Array<string | number>;

  /**
   * L'action à effectuer l'élément lorsqu'un changement de valeur est détecté
   * @param element L'élément sélectionné
   */
  onElementChange: (element: string) => void;

  /**
   * L'élément sélectionné par défaut. Si la valeur n'est pas trouvée
   * dans `elements`, un élément supplémentaire désactivé est ajouté à la liste.
   */
  defaultElement: string;

  /**
   * L'identifiant unique du composant.
   */
  id?: string;

  /**
   * La taille visuelle du sélecteur.
   *
   * Valeur par défaut : undefined, équivalent à 'md'
   */
  size?: 'sm' | 'lg';

  /**
   * Si un élément doit obligatoirement être sélectionné lors de la validation
   * d'un formulaire.
   *
   * Valeur par défaut : `false`
   */
  required?: boolean;
}

/**
 * Ce composant permet d'afficher une liste de choix
 * @param props Les propriétés nécessaires à l'affichage du composant
 * @returns Le composant rendu par React
 */
export default function ElementSelector(
  props: ElementSelectorProps
): React.ReactElement {
  return (
    <Form.Select
      id={props.id}
      data-testid={props.testId}
      size={props.size}
      onChange={e => {
        props.onElementChange(e.target.value);
      }}
      value={props.defaultElement}
      required={props.required ?? false}
    >
      {props.elements.findIndex(e => e === props.defaultElement) < 0 && (
        <option id="selector-option-default" disabled value="">
          Veuillez choisir une valeur
        </option>
      )}
      {props.elements.map<React.ReactElement>((e, i) => (
        <option
          key={i}
          id={`selector-option-${i}`}
          value={props.values?.at(i) ?? e}
        >
          {e}
        </option>
      ))}
    </Form.Select>
  );
}
