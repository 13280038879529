import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ReturnLayout } from '../../Components/ReturnLayout';
import { Col, Row } from 'react-bootstrap';

import { IndicatorForm } from '../../Components/IndicatorForm';
import type {
  IndicatorAttributeEditInput,
  IndicatorAttributeInput,
  ReadIndicatorQuery,
} from '../../__generated__/graphql';
import { useMutation } from '@apollo/client';
import { EDIT_INDICATOR } from '../../graphql/mutations';
import AggregateErrors from '../../utils/AggregateErrors';
import ContentLoader from '../../Components/ContentLoader';
import { READ_INDICATOR } from '../../graphql/queries';

/**
 * Ce composant permet la modification d'un indicateur. Ce composant est
 * accessible à partir de l'URL `/indicateurs/:id/modification`.
 * @returns Le composant rendu par React
 */
export default function EditIndicator(): React.ReactElement {
  // L'identifiant de l'indicateur à modifier
  const { id } = useParams();

  // La mutation nécessaire à la modification de l'indicateur
  const [editIndicator, { data, error, loading }] = useMutation(
    EDIT_INDICATOR,
    {
      onError: e => {
        console.error(e);
      },
    }
  );

  const errors = AggregateErrors(error, data?.editIndicator.errors);
  const isComplete = data !== undefined || error !== undefined;
  const navigate = useNavigate();

  let startingAttributes: IndicatorAttributeInput[];

  // La fonction de rappel permettant l'affichage du contenu dans la page
  const displayContent = (
    data: ReadIndicatorQuery | undefined
  ): React.ReactElement => {
    startingAttributes =
      data?.indicators?.items
        ?.at(0)
        ?.attributes.map<IndicatorAttributeInput>(a => ({
          attributeId: a.attribute.id,
          value: a.primaryValue ?? a.referenceValue?.id ?? '',
        })) ?? [];
    
    // https://bobbyhadz.com/blog/typescript-array-deep-copy
    const startingCopy = JSON.parse(JSON.stringify(startingAttributes)) as typeof startingAttributes;

    return (
      <>
        <IndicatorForm
          onFormSubmit={onFormSubmit}
          errors={errors}
          isComplete={isComplete}
          loading={loading}
          indicatorAttributes={startingCopy}
        />
      </>
    );
  };

  // La fonction de rappel lors de la soumission du formulaire de modification
  const onFormSubmit = (ias: IndicatorAttributeEditInput[]): void => {
    // On compare les 2 listes
    const edited: IndicatorAttributeEditInput[] = [];
    startingAttributes.forEach(e => {
      const a = ias.find(x => x.attributeId === e.attributeId);
      if (a === undefined) {
        // Si l'attribut est supprimé
        edited.push({ attributeId: e.attributeId, value: undefined });
      } else if (a.value !== e.value) {
        // Si l'attribut est modifié
        edited.push({ attributeId: a.attributeId, value: a.value });
      }
    });
    ias.forEach(e => {
      const a = startingAttributes.find(x => x.attributeId === e.attributeId);
      if (a === undefined) {
        // Si l'attribut est ajouté
        edited.push({ attributeId: e.attributeId, value: e.value })
      }
    });

    // On envoit la requête
    editIndicator({
      variables: {
        id: id ?? '',
        attributes: edited,
      }
    }).then(result => {
      if (result.errors == null && result.data?.editIndicator.errors == null) {
        navigate('/indicateurs');
      }
    });
  };

  return (
    <>
      <ReturnLayout href="/indicateurs">
        <Row className="justify-content-md-start">
          <Col md="10">
            <div className="h2">Modifier un indicateur</div>
          </Col>
        </Row>
        <Row>
          <ContentLoader query={READ_INDICATOR} data={displayContent} variables={{indicatorId: id ?? ''}} />
        </Row>
      </ReturnLayout>
    </>
  );
}
