import React from 'react';
import { Button } from 'react-bootstrap';

/**
 * Les propriétés du composant `<ButtonLoading>`.
 * Certaines propriétés sont optionnelles et détiennent
 * des valeurs par défaut.
 */
export interface ButtonLoadingProps {
  /**
   * Le composant rendu à l'intérieur du bouton.
   * Ceci correspond au contenu du bouton. La gestion
   * de l'état de chargement est assuré par le composant.
   */
  children: React.ReactNode;

  /**
   * Un indicateur de chargement. S'il est vrai, le bouton est désactivé
   * avec une animation pour indiquer le chargement.
   */
  loading: boolean;

  /**
   * Une variante Bootstrap pour le bouton.
   *
   * La valeur par défaut est `'primary'`
   */
  variant?: string;

  /**
   * Le type de bouton qui est mis en place.
   *
   * La valeur par défaut est `button`.
   */
  type?: 'submit' | 'reset' | 'button';

  /**
   * La taille du bouton mis en place.
   *
   * La valeur par défaut est `lg`.
   */
  size?: 'lg' | 'sm';

  /**
   * L'identicateur unique de ce bouton utilisé pour tester le composant
   */
  testid?: string;

  /**
   * Une fonction de rappel lorsque l'événement de clic
   * est déclenché sur le bouton.
   *
   * La fonction est ignorée si elle est omise des propriétés.
   */
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

/**
 * Un bouton avec un affichage de chargement.
 *
 * Ce composant permet d'uniformiser l'état de chargement dans toute
 * l'application.
 *
 * @param {ButtonLoadingProps} props Les propriétés nécessaires du
 * composant. Il nécessite minimalement un composant enfant et un
 * indicateur de chargement (`loading`)
 *
 * @returns Le composant rendu.
 */
export const ButtonLoading = React.forwardRef<HTMLButtonElement, ButtonLoadingProps>((props, ref) => {
  return (
    <Button
      ref={ref}
      variant={props.variant ?? 'primary'}
      type={props.type ?? 'button'}
      disabled={props.loading}
      onClick={props.onClick}
      size={props.size}
      data-testid={props.testid}
    >
      {props.loading && (
        <span className="spinner-border spinner-border-sm me-2" />
      )}
      {props.children}
    </Button>
  );
});
ButtonLoading.displayName = "ButtonLoading";
