import React, {  useEffect, useState} from 'react';
import { Alert, Spinner } from 'react-bootstrap';
import { Check, X } from 'react-bootstrap-icons';

/**
 * Les différents paramètres utilisé pour afficher
 * le composant de status de sauvegarde.
 */
interface SavingStatusProps {
  /**
   * Le status de chargement du composant.
   *
   * Ce paramètre est obligatoire.
   */
  loading: boolean;

  /**
   * Les erreurs d'exécution de la sauvegarde.
   *
   * Ce paramètre est obligatoire.
   */
  errors: string[];

  /**
   * Si la requête a reçu la réponse.
   *
   * Ce paramètre est obligatoire.
   */
  complete: boolean;

  /**
   * Délai après lequel le composant devient disparait après
   * avoir affiché un message de succès de sauvegarde.
   *
   * Ce paramètre est optionnel.
   * Valeur par défaut : 3 secondes
   */
  delay?: number;
}

/**
 * Ce composant permet d'afficher le status de sauvegarde
 * d'une requête ou d'une autre fonction.
 * @param props Les paramètres nécéssaire à l'affichage du composant
 * @returns Le composant rendu par React
 */
export default function SavingStatus(
  props: SavingStatusProps
): React.ReactElement {

  const delay = props.delay ?? 3000;

  const [visible, setVisible] = useState(true);

  // L'effet utilisé pour faire disparaitre le composant après le délais
  useEffect(() => {
    const timerId = setTimeout(() => {
      setVisible(false);
    }, delay);
    return () => { clearTimeout(timerId) };
  }, [props.loading, props.complete, props.errors]);

  // Logique d'affichage
  if (props.loading) {
    return (
      <Alert variant="secondary">
        <Spinner animation="border" size="sm" />
        <span>  Sauvegarde en cours...</span>
      </Alert>
    ); // On affiche le loading
  } else {
    if (props.complete) {
      if (props.errors.length === 0) {
        return (
          <Alert variant="success" show={visible}>
            <Check />
            <span>  Sauvegarde réussie</span>
          </Alert>
        ); // On affiche un succès
      } else {
        return (
          <Alert variant="danger" onClose={() => { setVisible(false) }} dismissible>
            <X />
            <span>  Une erreur est survenue</span>
            <ul>
              {props.errors.map((e, i) => (
                <li key={`error-${i}`}>{e}</li>
              ))}
            </ul>
          </Alert>
        ); // On affiche une erreur
      }
    } else {
      return <></>; // On affiche rien
    }
  }
}
