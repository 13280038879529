import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

/**
 * Les propriétés du composant `<ConfirmModal>`.
 * Certaines propriétés sont optionnelles et détiennent
 * des valeurs par défaut.
 */
export interface ConfirmModalProps {
  /**
   * Un indicateur si la modale est ouverte ou non.
   */
  show: boolean;

  /**
   * Une fonction de rappel lorsque la modale est fermée.
   * L'implémentation de base doit au moins changer l'état de
   * `show` dans le parent.
   */
  onClose: () => void;

  /**
   * Une fonction de rappel lorsqu'une personne a confirmé son action.
   * L'implémentation de base doit au moins changer l'état de `show`
   * dans le parent.
   */
  onConfirm: () => void;

  /**
   * Le titre de la modale. La valeur par défaut est:
   * `"confirmer l'action"`.
   */
  title?: string;

  /**
   * Le contenu de la modale sous la forme d'un élément de React.
   * La valeur par défaut est:
   * `"Êtes-vous certain(e) de vouloir continuer?"`.
   */
  children?: React.ReactElement;

  /**
   * Le contenu textuel du bouton de confirmation. La valeur par défaut
   * est: `'confirmer'`.
   */
  confirmButtonText?: string;
}

/**
 * Une modale de confirmation.
 *
 * Le composant permet d'abstraire l'affichage d'une modale.
 * Or, la gestion de l'affichage doit tout de même être fait
 * au sein du composant parent.
 *
 * @param {ConfirmModalProps} props Les propriétés nécessaires
 * du composant. Il nécessite minimalement l'état d'affichage et
 * les fonction de rappels.
 *
 * @returns Le composant rendu.
 */
export function ConfirmModal(props: ConfirmModalProps): React.ReactElement {
  const resolvedTitle = props.title ?? "Confirmer l'action";
  const resolvedMessage = props.children ?? (
    <>Êtes-vous certain(e) de vouloir continuer?</>
  );
  const resolvedConfirm = props.confirmButtonText ?? 'Confirmer';

  return (
    <Modal show={props.show} onHide={props.onClose}>
      <Modal.Header>
        <Modal.Title>{resolvedTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{resolvedMessage}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.onClose}>
          Annuler
        </Button>
        <Button onClick={props.onConfirm}>{resolvedConfirm}</Button>
      </Modal.Footer>
    </Modal>
  );
}
