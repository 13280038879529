import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import type { OverlayDelay } from 'react-bootstrap/esm/OverlayTrigger';
import type { Placement } from 'react-bootstrap/esm/types';

/**
 * Les propriétés nécessaires à l'affichage du composant
 */
export interface SimpleTooltipProps {
  /**
   * L'élément enfant pour lequel le `Tooltip` doit être affiché
   */
  children: React.ReactElement;

  /**
   * Le texte affiché dans le `Tooltip`
   */
  text: string;

  /**
   * Placement du `Tooltip` relatif au composant enfant
   * 
   * Valeur par défaut : `right`
   */
  placement?: Placement;
}

/**
 * Ce composant permet de simplifier l'utilisation d'un `Tooltip` simple
 * @param props Les propriétés nécessaire à l'affichage du composant
 * @returns Le composant rendu par React
 */
export default function SimpleTooltip(
  props: SimpleTooltipProps
): React.ReactElement {

  const overlayDelay: OverlayDelay = { show: 500, hide: 250 };

  return (
    <OverlayTrigger
      placement={props.placement ?? 'right'}
      delay={overlayDelay}
      overlay={<Tooltip>{props.text}</Tooltip>}
    >
      { props.children }
    </OverlayTrigger>
  );
}
