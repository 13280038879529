import { useMutation } from '@apollo/client';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { READ_ATTRIBUTE } from '../../graphql/queries';
import { Badge, Col, ListGroup, Row, Stack } from 'react-bootstrap';
import SimpleActionModal from '../../Components/SimpleActionModal';
import { DELETE_ATTRIBUTE } from '../../graphql/mutations';
import AggregateErrors from '../../utils/AggregateErrors';
import { ButtonLoading } from '../../Components/ButtonLoading';
import ContentLoader from '../../Components/ContentLoader';
import type { ReadAttributeQuery } from '../../__generated__/graphql';
import { ReturnLayout } from '../../Components/ReturnLayout';

export default function ReadAttribute(): React.ReactElement {
  const [confirmModalShow, setConfirmModalShow] = useState(false);
  const [deleteAttribute, { loading, data: deleteData, error }] =
    useMutation(DELETE_ATTRIBUTE);

  const deleteErrors = AggregateErrors(
    error,
    deleteData?.deleteAttribute.errors
  );
  const navigate = useNavigate();

  const onClose = (): void => {
    setConfirmModalShow(false);
  };

  const onDeleteAttribute = (): void => {
    setConfirmModalShow(true);
  };

  const onConfirm = (): void => {
    setConfirmModalShow(false);
    deleteAttribute({
      variables: {
        attributeId: id ?? '',
      },
      onError: () => {},
    })
      .then(result => {
        if (
          (result.data?.deleteAttribute.errors ?? []).length === 0 &&
          (result.data?.deleteAttribute.attribute?.id ?? '') !== ''
        ) {
          navigate('/attributs');
        }
      })
      .finally(() => {
        setConfirmModalShow(false);
      });
  };
  const { id } = useParams();

  const dataPlacement = (
    data: ReadAttributeQuery | undefined
  ): React.ReactElement => {
    const attribute = data?.attributes?.items?.at(0);

    const valeurs: React.ReactElement = (
      <>
        {attribute?.values?.map((x, i) => (
          <ListGroup.Item key={i}>{x.value}</ListGroup.Item>
        )) ?? <></>}
      </>
    );

    if (attribute == null) return <></>;
    return (
      <>
        <Row>
          <Col>
            <SimpleActionModal
              errorMessages={deleteErrors}
              genericErrorMessage="Des erreurs sont survenues lors de la suppression de l'attribut."
              show={confirmModalShow}
              onClose={onClose}
              onConfirm={onConfirm}
              title="Confirmer la suppression"
              confirmButtonText="Supprimer l'attribut"
            >
              <>
                <p>
                  L&apos;action est irréversible, êtes-vous certain(e) de
                  vouloir supprimer l&apos;attribut{' '}
                  <b>{attribute?.name}</b>?
                </p>
              </>
            </SimpleActionModal>
          </Col>
        </Row>
        <Row>
          <Col>
            <Stack direction="horizontal" gap={2}>
              <h2 className="m-0">{attribute.name}</h2>
              {attribute.mandatory ? (
                <Badge bg="danger">Obligatoire</Badge>
              ) : (
                <></>
              )}
              {attribute.unique ? <Badge bg={'danger'}>Unique</Badge> : <></>}
            </Stack>
          </Col>
          {attribute?.canBeDeleted && (
            <Col md={'auto'}>
              <Stack direction="horizontal" gap={2}>
                <ButtonLoading
                  loading={loading}
                  onClick={onDeleteAttribute}
                  variant="danger"
                >
                  <span>Supprimer</span>
                </ButtonLoading>
              </Stack>
            </Col>
          )}
        </Row>
        <hr />
        <Row className="mb-3">
          <Col>
            <div>
              {attribute.description !== ''
                ? attribute.description
                : 'Aucune description disponible'}
            </div>
          </Col>
        </Row>
        <hr />
        <Row className="mb-3">
          <Col md={'auto'}>
            <ListGroup>
              <ListGroup.Item>
                Créé le : {new Date(attribute.createdOn).toLocaleString()}
              </ListGroup.Item>
              <ListGroup.Item>
                Modifié le : {new Date(attribute.editedOn).toLocaleString()}
              </ListGroup.Item>
              <ListGroup.Item>
                Créateur : {attribute.owner.firstName}{' '}
                {attribute.owner.lastName}
              </ListGroup.Item>
              <ListGroup.Item>
                Type d&apos;attribut : {attribute.readableValueType}
              </ListGroup.Item>
              <ListGroup.Item>
                Nombre d&apos;indicateurs : {attribute.numberOfLinkedIndicators}
              </ListGroup.Item>
            </ListGroup>
          </Col>
        </Row>
        {attribute.values != null && attribute.values.length !== 0 && (
          <Row className="mb-3 mt-4">
            <Col md={3}>
              <h4>Valeurs possibles</h4>
              <ListGroup>{valeurs}</ListGroup>
            </Col>
          </Row>
        )}
      </>
    );
  };

  return (
    <ReturnLayout href="/attributs">
      <Row>
        <Col>
          <ContentLoader
            query={READ_ATTRIBUTE}
            variables={{ attributeId: id ?? '' }}
            data={dataPlacement}
          />
        </Col>
      </Row>
    </ReturnLayout>
  );
}
