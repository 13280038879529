import { useMutation, useQuery } from '@apollo/client';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { READ_ATTRIBUTE } from '../../graphql/queries';
import AttributeForm, { type FormData } from '../../Components/AttributeForm';
import { EDIT_ATTRIBUTE } from '../../graphql/mutations';
import AggregateErrors from '../../utils/AggregateErrors';
import Alert from 'react-bootstrap/Alert';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { ReturnLayout } from '../../Components/ReturnLayout';

/**
 * Ce composant permet la modification d'un attribut d'indicateur. Ce
 * composant est disponible à partir de l'URL `/attributs/:id/modifier`
 * @returns Le composant rendu par React
 */
export default function EditAttribute(): React.ReactElement {
  const { id } = useParams();
  const queryResult = useQuery(READ_ATTRIBUTE, {
    variables: { attributeId: id ?? '' },
  });

  const attribute = queryResult.data?.attributes?.items?.at(0);
  const hasUndeletableAttributeValue = (attribute?.values ?? []).some(
    v => !v.canBeDeleted
  );

  const [editAttribute, { loading, data: editData, error }] = useMutation(
    EDIT_ATTRIBUTE,
    {
      onError: e => {
        console.error(e.message);
      },
    }
  );

  const navigate = useNavigate();

  const onFormSubmit = (
    event: React.FormEvent<HTMLFormElement>,
    data: FormData,
    setValidated: React.Dispatch<React.SetStateAction<boolean>>
  ): void => {
    if (!loading) {
      event.preventDefault();
      event.stopPropagation();
      const values = data.values?.map<{ id: string; value: string }>(e => {
        return { id: e.id, value: e.value };
      });
      editAttribute({
        variables: {
          id: id ?? '',
          name: data.name,
          description: data.description,
          valueType: data.valueType,
          mandatory: data.mandatory,
          unique: data.unique,
          values,
        },
      }).then(result => {
        if (
          result.errors == null &&
          result.data?.editAttribute.errors == null
        ) {
          navigate('/attributs');
        }
      });
      setValidated(true);
    }
  };

  const errors = AggregateErrors(error, editData?.editAttribute.errors);
  const isComplete = editData !== undefined || error !== undefined;

  return (
    <ReturnLayout href="/attributs">
      <Row>
        <Col>
          <div className="h2">Modifier un attribut</div>
        </Col>
      </Row>
      {hasUndeletableAttributeValue && (
        <Row>
          <Col>
            <Alert variant="warning" dismissible>
              Une ou plusieurs valeurs d&apos;attributs sont actuellement
              utilisés par des indicateurs. Le bouton de retrait de ces valeurs
              d&apos;attribut est absent puisque ces valeurs ne peuvent être
              supprimées.
            </Alert>
          </Col>
        </Row>
      )}

      <Row>
        <Col>
          <AttributeForm
            onFormSubmit={onFormSubmit}
            isComplete={isComplete}
            errors={errors}
            loading={loading}
            formData={{
              name: attribute?.name,
              description: attribute?.description,
              mandatory: attribute?.mandatory,
              unique: attribute?.unique,
              valueType: attribute?.valueType,
              values: attribute?.values,
            }}
          />
        </Col>
      </Row>
    </ReturnLayout>
  );
}
